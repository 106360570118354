// import { ThemeSwitcher } from "@/components/ThemeSwitcher";
// import { CircleIcon } from "@/components/Icons/CircleIcon";
import { DiscordIcon } from "@/components/Icons/DiscordIcon";
import { TwitterIcon } from "@/components/Icons/TwitterIcon";
// import { GithubIcon } from "@/components/Icons/GithubIcon";
import { TelegramIcon } from "@/components/Icons/TelegramIcon";
import { LinkedInIcon } from "@/components/Icons/LinkedInIcon";
import { IoIcon } from "@/components/Icons/IoIcon";
import { useUserStore } from "@/store/user";
import { Separator } from "@/components/ui/separator";
import { useCookieStore } from "@/store/cookie";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { SimpleTooltip } from "../Tooltip/SimpleTooltip";

export const MainFooter = () => {
  const theme = useUserStore((state) => state.theme);
  const setIsSelectionModalOpen = useCookieStore((state) => state.setIsSelectionModalOpen);

  return (
    <div className="flex flex-col gap-4 border-t border-t-gray-dark-900 bg-gray-dark-600 p-6 lg:px-20 lg:py-6">
      <div className="flex flex-col items-start gap-4 lg:flex-row lg:items-center ">
        <div className="order-1 flex flex-row items-center justify-start gap-4">
          <IoIcon className="h-[14px] text-white" />
          {/* <div className="flex flex-row items-center gap-2">
        <CircleIcon className="h-[8px] text-green-light-800" />
        <p className="subtext-x-small text-green-light-800">All services are online</p>
      </div> */}
          <iframe
            src={`https://ionet.betteruptime.com/badge?theme=${theme}`}
            width="215"
            data-testid={"SocialLinks_betterUptime"}
            height="30"
          ></iframe>
        </div>
        <Separator className="order-1 hidden max-lg:block" />
        <div className="order-2 hidden flex-1 lg:block"></div>
        <div className="order-last flex flex-row gap-5 lg:order-3">
          {SOCIAL_LINKS.map((option) => {
            const { title, link } = option;

            return (
              <a
                key={title}
                href={link}
                data-testid={`SocialLinks_${title}`}
                className="cursor-pointer text-gray-dark-1000 hover:text-black dark:hover:text-white"
                target={"_blank"}
              >
                <option.icon className="h-[20px] max-w-[20px]" />
              </a>
            );
          })}
        </div>
        <Separator orientation="vertical" className="order-4 hidden h-[20px] lg:flex" />
        <div className="order-5">
          <SimpleTooltip
            id="footer-company-notice"
            content={
              <div className="subtext-x-small flex items-center gap-2 font-normal text-gray-dark-1000">
                <div className="w-[12px]">
                  <InfoCircledIcon className="h-[12px] w-[12px]" />
                </div>
                <span>
                  The IO tokens will not be offered in the <br className="md:hidden" />
                  United States or to U.S persons or to <br className="sm:hidden" />
                  residents of certain other prohibited jurisdictions.
                </span>
              </div>
            }
          >
            <p className="subtext-x-small text-gray-dark-1000">
              ©{new Date().getFullYear()}, io.net, inc.
            </p>
          </SimpleTooltip>
        </div>
        <Separator orientation="vertical" className="order-6 hidden h-[20px] lg:flex" />
        <a
          className="subtext-x-small order-7 cursor-pointer text-gray-dark-1000 hover:text-white"
          onClick={() => {
            setIsSelectionModalOpen(true);
          }}
        >
          Manage Cookies
        </a>
      </div>
    </div>
  );
};

const SOCIAL_LINKS = [
  // {
  //   title: "Github",
  //   icon: GithubIcon,
  //   link: "",
  //   disabled: true
  // },
  {
    title: "Telegram",
    icon: TelegramIcon,
    link: "http://t.me/io_net"
  },
  {
    title: "Discord",
    icon: DiscordIcon,
    link: "https://discord.com/invite/ionetofficial"
  },
  {
    title: "Twitter",
    icon: TwitterIcon,
    link: "https://twitter.com/ionet"
  },
  {
    title: "Linkedin",
    icon: LinkedInIcon,
    link: "https://www.linkedin.com/company/ionet-official"
  }
];
