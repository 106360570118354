import { useUserStore } from "@/store/user";

type PageWrapperProps = {
  children: React.ReactNode;
  testId?: string;
};

export const PageWrapper: React.FC<PageWrapperProps> = ({ children, testId }) => {
  const theme = useUserStore((state) => state.theme);

  return (
    <div
      className={`flex min-h-full flex-1 flex-col justify-stretch ${
        theme === "dark" ? "dark" : "light"
      }`}
      data-testid={testId}
    >
      {children}
    </div>
  );
};
