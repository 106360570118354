import { useEffect, useState } from "react";
// import parse from "html-react-parser";
import { fetchLatestTerms } from "@/utils/legal";
import { MarkdownRenderer } from "../MarkdownRenderer/MarkdownRenderer";

const TermsAndConditionsToc = () => {
  const [toc, setToc] = useState<string>();

  useEffect(() => {
    (async () => {
      // const newCopy = await removeContentElement();
      // setToc(parse(newCopy));

      const { toc: newToc } = await fetchLatestTerms();
      setToc(newToc);
    })();
  }, []);

  if (!toc) {
    return null;
  }

  return (
    <div>
      <div className="title-h4 mb-6 font-medium text-white">Table of Contents</div>
      {/* <div>{toc}</div> */}
      <MarkdownRenderer content={toc} />
    </div>
  );
};

export default TermsAndConditionsToc;

// const LEGAL_TEXT_COLOR = "#989898";
// const LEGAL_FONT_SIZE = "14px";

// const removeContentElement = async () => {
//   const response = await fetch("/assets/copy/terms.html");
//   let copy = await response.text();

//   copy = copy.split("color: black").join(`color: ${LEGAL_TEXT_COLOR}`);
//   copy = copy.split("color: #0d0d0d").join(`color: ${LEGAL_TEXT_COLOR}`);
//   copy = copy.split('font-family: "Times New Roman", serif;').join("");
//   copy = copy.split("color: #2e74b5").join(`color: ${LEGAL_TEXT_COLOR}`);
//   copy = copy.split("color: #1f4d78").join(`color: ${LEGAL_TEXT_COLOR}`);
//   copy = copy.split("font-family: &quot;Times New Roman&quot;, serif;").join("");
//   copy = copy.split("11pt").join(LEGAL_FONT_SIZE);
//   copy = copy.split("12pt").join(LEGAL_FONT_SIZE);

//   const tempContainer = document.createElement("div");
//   tempContainer.innerHTML = copy;

//   const tocPages = tempContainer.querySelectorAll(".toc-page");
//   tocPages.forEach((element) => element.remove());

//   const content = tempContainer.querySelector(".content") as HTMLElement;
//   content.remove();

//   return tempContainer.innerHTML;
// };
