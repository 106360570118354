import { IconProps } from "@/components/Icons";

type OptionType = {
  label: React.ReactNode;
  value: string;
  icon?: React.FC<IconProps>;
  customIcon?: React.FC;
  disabled?: boolean;
  description?: string;
  component?: React.ReactNode;
  customRadio?: React.ReactNode;
  className?: string;
  isLabelUnderneath?: boolean;
};

export type RadioCardInputProps = {
  value?: string;
  options: OptionType[];
  disabled?: boolean;
  name: string;
  onChange: (value: string) => void;
  onSelect?: (value: string) => void;
  className?: string;
};

export default function RadioCardInput({
  onChange,
  onSelect,
  value = "",
  options,
  className
}: RadioCardInputProps) {
  return (
    <div className={`grid gap-2 lg:grid-cols-2 ${className}`}>
      {options.map((option) => {
        const isActive = option.value === value;
        return (
          <div
            key={option.value}
            onClick={() => {
              if (option.disabled) {
                return;
              }

              onChange(option.value);
              onSelect?.(option.value);
            }}
            className={`
            ${isActive ? "border-gray-dark-1300" : "border-gray-800"}
            ${option.disabled ? "opacity-50" : "cursor-pointer hover:border-gray-dark-1300"}
            rounded border bg-gray-dark-1700 p-6 ${option.className}
          `}
          >
            <div className={`flex flex-1 items-center justify-between`}>
              {option.icon && (
                <div className="mr-4 h-[32px] w-[32px] rounded bg-gradient-to-b from-[#909090] to-[#2A2A2A] p-[1px]">
                  <div className="flex h-full items-center justify-center rounded bg-black">
                    <option.icon className="h-[20px] w-[20px]" />
                  </div>
                </div>
              )}
              {option.customIcon && <option.customIcon />}
              {!option.isLabelUnderneath && (
                <div className="mr-4 flex-1">
                  <div className="subtext-small lg:subtext-medium text-black dark:text-white">
                    {option.label}
                  </div>
                </div>
              )}
              {option.customRadio ? (
                option.customRadio
              ) : (
                <div
                  className={`radio flex h-[24px] w-[24px] items-center justify-center rounded-full border ${isActive ? "border-gray-dark-700" : "border-gray-dark-700"}`}
                >
                  {isActive && (
                    <div className="h-[10px] w-[10px] rounded-full bg-black dark:bg-gray-light-1000"></div>
                  )}
                </div>
              )}
            </div>
            {option.isLabelUnderneath && (
              <div className="mt-2 flex-1">
                <div className="subtext-small lg:subtext-medium">{option.label}</div>
              </div>
            )}
            {option.description ? (
              <div
                className={`subtext-x-small mt-2 text-gray ${option.customIcon || option.icon ? "lg:ml-12" : ""}`}
              >
                {option.description}
              </div>
            ) : null}
            {option.component}
          </div>
        );
      })}
    </div>
  );
}
