import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const badgeVariants = cva(
  "inline-flex items-center rounded-[6px] border subtext-x-small transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "border-gray-dark-1500 bg-gray-dark-1100 text-white hover:bg-primary/80",
        secondary:
          "bg-gray-dark-1100 text-gray-dark-1500 [.group-active_&]:text-black [.group-active_&]:bg-white",
        primary: 'border-transparent bg-[#36A65F3D] text-[#40BA6F] hover:bg-[#36A65F3D]/80"',
        destructive: "border-transparent bg-[#EF44443D] text-red-dark-300 hover:bg-destructive/80",
        outline: "text-foreground",
        subtle: "rounded subtext-x-small text-gray-dark-1000 border-gray-dark-900",
        warn: "border-transparent bg-[#EF8F143D] text-[#E6770A] hover:bg-destructive/80"
      },
      size: {
        default: "px-1.5 py-0.25",
        md: "px-2 py-1"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant, size }), className)} {...props} />;
}

export { Badge, badgeVariants };
