import { cn } from "@/utils/styles";
export type TextInputProps = {
  value?: string;
  type?: string;
  disabled?: boolean;
  name: string;
  placeholder?: string;
  className?: string;
  onChange: (value: string) => void;
};

export default function TextInput({
  onChange,
  value = "",
  name,
  placeholder,
  type = "text",
  className = ""
}: TextInputProps) {
  return (
    <input
      style={{ boxShadow: "none" }}
      type={type}
      value={value}
      name={name}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      className={cn(
        "subtext-small h-[40px] flex-1 rounded-[8px] border border-gray-dark-900 bg-black px-3 py-[10px] text-gray-dark-1000 placeholder:text-gray-dark-900",
        className
      )}
    ></input>
  );
}
