import { useEffect, useRef, useState, FC } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { TermsAndConditionsText } from "../TermsAndConditionsText/TermsAndConditionsText";
import { Checkbox } from "@/components/ui/checkbox";
// import env from "@/env.ts";
import TermsAndConditionsToc from "../TermsAndConditionsToc/TermsAndConditionsToc";
import { useToast } from "@/components/ui/use-toast";
import { ErrorFeedback } from "../ErrorFeedback/ErrorFeedback";

export type TermsAndConditionsModalProps = {
  isOpen: boolean;
  onClose: (isConfirmed: boolean) => void;
};

export const TermsAndConditionsModal: FC<TermsAndConditionsModalProps> = ({ isOpen, onClose }) => {
  const [fetchCopyError, setFetchCopyError] = useState<Error>();
  const [isComprehended, setIsComprehended] = useState(false);
  const [isTermsRead, setIsTermsRead] = useState(false);
  const { toast } = useToast();

  const listInnerRef = useRef<HTMLDivElement | null>(null);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const isNearBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

      if (isNearBottom) {
        setIsTermsRead(true);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const listElement = listInnerRef.current;
      if (isOpen && listElement) {
        listElement.addEventListener("scroll", onScroll);
        return () => {
          listElement.removeEventListener("scroll", onScroll);
        };
      }
    });
  }, [isOpen]);

  const handleCheck = (checked: boolean) => setIsComprehended(checked);

  const handleCheckbox = (e: React.MouseEvent) => {
    e.stopPropagation();

    const content = document.querySelector(".terms-content");

    if (!isTermsRead && content) {
      const initialScrollTop = content.scrollTop;
      const scrollDistance = 20;

      content.scrollTo({
        top: initialScrollTop + scrollDistance,
        behavior: "smooth"
      });

      setTimeout(() => {
        content.scrollTo({
          top: initialScrollTop,
          behavior: "smooth"
        });
      }, 200);
    }
  };

  const handleAgreement = () => {
    if (isComprehended) {
      onClose(true);
    } else {
      toast({ title: "Please read fully and confirm the Terms Of Use!", variant: "warn" });
    }
  };

  return (
    <Dialog open={isOpen} modal defaultOpen={isOpen}>
      <DialogContent
        className="h-full max-h-[695px] w-full max-w-[1030px] gap-0 overflow-y-auto !px-0 py-0"
        showCloseIcon={false}
      >
        <DialogHeader className="gap-3 border-b border-gray-dark-1100">
          <DialogTitle className="flex flex-col gap-2">
            <div className="title-h4 px-[32px] pb-[18px] pt-[24px] text-white">Terms of Use</div>
          </DialogTitle>
        </DialogHeader>

        {fetchCopyError ? (
          <ErrorFeedback message={fetchCopyError.message} className="flex-1" />
        ) : (
          <div className="flex justify-between">
            <div
              ref={listInnerRef}
              className="custom-scrollbar terms-content custom-scrollbar-filled my-6 ml-8 mr-2 flex h-full max-h-[420px] min-h-[420px] w-full flex-col overflow-y-auto scroll-smooth bg-gradient-to-b from-[#0A0A0A0] to-[#000000] text-sm text-gray-dark-1900 sm:max-h-[440px] sm:min-h-[440px] sm:max-w-[65%]"
            >
              {/* <div className="subtext-small mb-3 text-gray-light-1500">
              Effective date: {env.LATEST_APPROVED_TERMS_VERSION}
            </div> */}
              <TermsAndConditionsText onError={setFetchCopyError} />
            </div>
            <div className="custom-scrollbar custom-scrollbar-filled subtext-small my-6 ml-2 mr-2 flex h-full max-h-[440px] min-h-[440px] max-w-[30%] flex-col overflow-y-auto scroll-smooth bg-gradient-to-b from-[#0A0A0A0] to-[#000000] text-gray-dark-1900 max-sm:hidden">
              <TermsAndConditionsToc />
            </div>
          </div>
        )}
        <div className="relative flex flex-col border-t border-gray-dark-1100">
          <div className="subtext-medium flex flex-row items-center gap-4 p-[16px] text-gray-dark-1000 [overflow-wrap:anywhere]">
            <Checkbox
              id="terms"
              disabled={!isTermsRead}
              checked={isComprehended}
              onClick={handleCheckbox}
              onCheckedChange={handleCheck}
            />
            <label
              htmlFor="terms"
              className={`flex cursor-pointer select-none flex-row items-center gap-0 text-base text-gray-dark-1900 
                ${isTermsRead ? "default" : "cursor-not-allowed"} ${isTermsRead ? "opacity-1" : "opacity-50"}`}
              onClick={handleCheckbox}
            >
              I have read, fully comprehended, and agreed to the Terms of Use.
            </label>
          </div>
          <DialogFooter className="flex flex-row !justify-center gap-2 rounded-b-lg border-t border-gray-dark-1100 px-6 py-4 dark:border-gray-dark-900 dark:bg-black">
            <div className="flex-1"></div>
            <Button
              onClick={() => onClose(false)}
              variant="error"
              size="sm"
              className="min-w-[95px] cursor-pointer"
            >
              Disagree and leave
            </Button>
            <Button
              // disabled={!isComprehended}
              onClick={handleAgreement}
              variant="outline"
              size="sm"
              className={`min-w-[95px] cursor-pointer ${!isComprehended ? "bg-[#0A0A0A] text-[#444444]" : "bg-gray-light-1000 text-black "}`}
            >
              Agree and Proceed
            </Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
};
