import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

const ipv4Regex =
  /^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])){3}$/;

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const validatePort = (port?: number, isRequired?: boolean) => {
  if (!isRequired && port !== 0 && !port) {
    return "";
  }
  return port === 0 || (port && port >= 0 && port <= 65535)
    ? ""
    : "Port must be between 0 and 65535";
};

export const isValidIP = (ip?: string, isRequired?: boolean) => {
  if (!isRequired && !ip) {
    return "";
  }
  return ip && ipv4Regex.test(ip) ? "" : "Invalid IP address format";
};

export const filterProps = (
  data: { [k: string]: unknown },
  filterKey?: string[]
): { [p: string]: unknown } =>
  Object.fromEntries(
    Object.entries(data).filter(([key, value]) => (filterKey ? !filterKey.includes(key) : value))
  );

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const isValidSSHKey = (sshKey?: string) => {
  if (!sshKey) {
    return false;
  }
  /* https://github.com/nemchik/ssh-key-regex */
  const sshCombinedPattern =
    /^(ssh-dss AAAAB3NzaC1kc3|ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNT|sk-ecdsa-sha2-nistp256@openssh.com AAAAInNrLWVjZHNhLXNoYTItbmlzdHAyNTZAb3BlbnNzaC5jb2|ssh-ed25519 AAAAC3NzaC1lZDI1NTE5|sk-ssh-ed25519@openssh.com AAAAGnNrLXNzaC1lZDI1NTE5QG9wZW5zc2guY29t|ssh-rsa AAAAB3NzaC1yc2)[0-9A-Za-z+/]+[=]{0,3}(\s.*)?$/;
  // const sshRsaPattern = /^ssh-rsa\s+[A-Za-z0-9+/=]+\s*(.+)?$/;
  // const sshEd25519Pattern = /^ssh-ed25519\s+[A-Za-z0-9+/=]+\s*(.+)?$/;
  // const sshEcdsaPattern = /^ecdsa-sha2-nistp(256|384|521)\s+[A-Za-z0-9+/=]+\s*(.+)?$/;
  // return (
  //   sshRsaPattern.test(sshKey) || sshEd25519Pattern.test(sshKey) || sshEcdsaPattern.test(sshKey)
  // );
  return sshCombinedPattern.test(sshKey);
};

export const isValidJsonString = (data: string) => {
  try {
    JSON.parse(data);
  } catch (e) {
    return false;
  }
  return true;
};
