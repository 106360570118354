import { ClustersIcon } from "@/components/Icons/ClustersIcon";
import { EarningsIcon } from "@/components/Icons/EarningsIcon";
import { KubernetesIcon } from "@/components/Icons/KubernetesIcon";
import { ModelsIcon } from "@/components/Icons/ModelsIcon";
import { Tool } from "@/types";
import { HomeIcon } from "@/components/Icons/HomeIcon";
import { CloudIcon } from "@/components/Icons/CloudIcon";
import { WorkerIcon } from "@/components/Icons/WorkerIcon";
import { ExplorerIcon } from "@/components/Icons/ExplorerIcon";
import { InferenceIconRound } from "@/components/Icons/InferenceIconRound";
import { CloudLogo } from "@/components/Icons/CloudLogo";
import { WorkerLogo } from "@/components/Icons/WorkerLogo";
import { ExplorerLogo } from "@/components/Icons/ExplorerLogo";
import { LocationIcon } from "@/components/Icons/LocationIcon";
import { WorkerIcon2 } from "@/components/Icons/WorkerIcon2";
import { AuthIcon } from "@/components/Icons/AuthIcon";
import { AuthLogo } from "@/components/Icons/AuthLogo";
import { CoinLogo } from "@/components/Icons/CoinLogo";
import { CoinIcon } from "@/components/Icons/CoinIcon";
import { SettingsIcon } from "@/components/Icons/SettingsIcon";
import { DocumentIcon } from "@/components/Icons/DocumentIcon";
import { AIModelsIcon } from "@/components/Icons/AIModelsIcon";
import { AIModelsLogo } from "@/components/Icons/AIModelsLogo";
import env from "@/env";
import BC8WhiteIcon from "@/components/Icons/BC8WhiteIcon";

export const TOOL_OPTIONS = [
  {
    value: "cloud",
    label: "Cloud",
    icon: CloudIcon,
    logo: CloudLogo,
    href: "/cloud/home",
    prefix: "/cloud",
    bg: "/assets/images/bg_cloud.png",
    menuItems: [
      {
        value: "home",
        label: "Home",
        icon: HomeIcon,
        href: "/cloud/home"
      },
      {
        value: "clusters",
        label: "Ray",
        icon: ClustersIcon,
        href: "/cloud/clusters"
      },
      {
        value: "megaClusters",
        label: "Mega-Ray",
        icon: ClustersIcon,
        href: "/cloud/mega-clusters"
      },
      {
        value: "bareMetal",
        label: "Bare Metal",
        icon: ClustersIcon,
        href: "/cloud/bare-metal",
        disabled: !env.FEATUREFLAG_BARE_METAL_DEPLOY
      },
      {
        value: "kubernetes",
        label: "Kubernetes",
        icon: KubernetesIcon,
        href: "/cloud/kubernetes",
        disabled: !env.FEATUREFLAG_KUBERNETES_DEPLOY
      },
      {
        value: "models",
        label: "Models",
        icon: ModelsIcon,
        href: "/cloud/models",
        disabled: true
      }
    ]
  },
  {
    value: "worker",
    label: "worker",
    logo: WorkerLogo,
    icon: WorkerIcon,
    href: "/worker/devices",
    prefix: "/worker",
    bg: "/assets/images/bg_worker.png",
    menuItems: [
      {
        value: "devices",
        label: "Workers",
        icon: WorkerIcon2,
        href: "/worker/devices"
      },
      {
        value: "Earnings & Rewards",
        label: "Earnings & Rewards",
        icon: EarningsIcon,
        href: "/worker/earnings-and-rewards"
      },
      // {
      //   value: "staking-debug",
      //   label: "Staking (Debug)",
      //   href: "/worker/staking"
      // },
      {
        value: "staking",
        label: "Staking",
        href: "/worker/integrated-staking"
      }
    ]
  },
  {
    value: "explorer",
    label: "Explorer",
    icon: ExplorerIcon,
    logo: ExplorerLogo,
    href: "/explorer/home",
    prefix: "/explorer",
    bg: "/assets/images/bg_explorer.png",
    menuItems: [
      {
        value: "home",
        label: "Home",
        icon: HomeIcon,
        href: "/explorer/home"
      },
      {
        value: "blockRewards",
        label: "Block Rewards",
        icon: InferenceIconRound,
        href: "/explorer/block-rewards"
      },
      {
        value: "networks",
        label: "Network Map",
        icon: LocationIcon,
        href: "/explorer/map"
      },
      {
        value: "clusters",
        label: "Clusters",
        icon: ClustersIcon,
        href: "/explorer/clusters"
      },
      {
        value: "devices",
        label: "Workers",
        icon: WorkerIcon2,
        href: "/explorer/devices"
      },
      {
        value: "inferences",
        label: "Inferences",
        icon: InferenceIconRound,
        href: "/explorer/inferences"
      }
    ]
  },
  {
    value: "id",
    label: "IO ID",
    icon: AuthIcon,
    logo: AuthLogo,
    href: "/id/account/settings",
    bg: "/assets/images/bg_auth.png",
    menuItems: [
      {
        value: "settings",
        label: "Account Settings",
        icon: SettingsIcon,
        href: "/id/account/settings"
      },
      {
        value: "funds",
        label: "Manage Funds",
        icon: InferenceIconRound,
        href: "/id/funds"
      },
      {
        value: "acknowledgment",
        label: "User Acknowledgment",
        icon: DocumentIcon,
        href: "/id/account/acknowledgment"
      }
    ]
  },
  env.INTELLIGENCE_ENABLED
    ? {
        value: "ai",
        icon: AIModelsIcon,
        logo: AIModelsLogo,
        href: "/ai/models",
        bg: "/assets/images/bg_models.png",
        menuItems: [
          {
            value: "models",
            label: "AI Models",
            href: "/ai/models"
          },
          {
            value: "agents",
            label: "AI Agents",
            href: "/ai/agents"
          },
          {
            value: "api-keys",
            label: "API Keys",
            href: "/ai/api-keys"
          }
        ]
      }
    : {
        value: "coin",
        label: "IO Coin",
        icon: CoinIcon,
        logo: CoinLogo,
        href: "https://coinmarketcap.com/currencies/io/",
        bg: "/assets/images/bg_coin.png"
      },
  {
    value: "bc8",
    label: "BC8",
    icon: BC8WhiteIcon,
    href: "https://bc8.ai",
    bg: "/assets/images/bg_coin.png"
  }
] as Tool[];
