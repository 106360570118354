import { useEffect, useState, FC } from "react";
// import parse from "html-react-parser";
// import env from "@/env.ts";
import { Loader } from "../Loader/Loader";
import { fetchLatestTerms } from "@/utils/legal";
import { MarkdownRenderer } from "../MarkdownRenderer/MarkdownRenderer";

type TermsAndConditionsTextProps = {
  showHeader?: boolean;
  onError?: (e: Error) => void;
};

export const TermsAndConditionsText: FC<TermsAndConditionsTextProps> = ({
  showHeader,
  onError
}) => {
  const [fetchCopyError, setFetchCopyError] = useState<Error>();
  const [copy, setCopy] = useState<string>();

  useEffect(() => {
    (async () => {
      // const newCopy = await fetchLegalCopy();
      // setCopy(parse(newCopy));

      try {
        const { contents } = await fetchLatestTerms();
        setCopy(contents);
        setFetchCopyError(undefined);
      } catch (e) {
        if (e instanceof Error) {
          setFetchCopyError(e);
          onError?.(e);
        }
      }
    })();
  }, [onError]);

  if (!copy && !fetchCopyError) {
    return (
      <div className="flex h-[700px] pr-[6rem]">
        <div
          className="flex min-h-[100px] flex-1 flex-col items-end justify-center"
          data-testid="ContentLoader"
        >
          <Loader className={"flex-1"} color={"#ffffff"} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <>
        {showHeader && (
          <>
            {/* <div className="subtext-small mb-3 text-gray-light-1500">
              Last Updated: {env.LATEST_APPROVED_TERMS_VERSION}
            </div> */}
            <div className="title-h4 mb-6 text-white">TERMS OF USE</div>
          </>
        )}
        {copy && <MarkdownRenderer content={copy} />}
        {/* <p
          style={{
            marginTop: "0pt",
            marginLeft: "48pt",
            marginBottom: "0pt",
            fontSize: "11pt"
          }}
        >
          All feedback, comments, requests for technical support, and other communications relating
          to the Services should be directed to
          <a href="mailto:support@io.net" style={{ textDecoration: "none" }}>
            <span style={{ color: "#f5a623" }}> support@io.net</span>
          </a>
          .&nbsp;&nbsp;
        </p> */}
      </>
    </div>
  );
};

// const LEGAL_TEXT_COLOR = "#989898";
// const LEGAL_FONT_SIZE = "14px";

// const fetchLegalCopy = async () => {
//   const response = await fetch("/assets/copy/terms.html");
//   let copy = await response.text();

//   copy = copy.split("color: black").join(`color: ${LEGAL_TEXT_COLOR}`);
//   copy = copy.split("color: #0d0d0d").join(`color: ${LEGAL_TEXT_COLOR}`);
//   copy = copy.split('font-family: "Times New Roman", serif;').join("");
//   copy = copy.split("font-family: &quot;Times New Roman&quot;, serif;").join("");
//   copy = copy.split("11pt").join(LEGAL_FONT_SIZE);
//   copy = copy.split("12pt").join(LEGAL_FONT_SIZE);

//   const tempContainer = document.createElement("div");
//   tempContainer.innerHTML = copy;

//   const toc = tempContainer.querySelector(".table-of-contents") as HTMLElement;
//   toc.remove();

//   const removeableElements = tempContainer.querySelectorAll(".hide");
//   console.log(removeableElements);

//   removeableElements.forEach((el) => {
//     el.innerHTML = "";
//   });

//   return tempContainer.innerHTML;
// };
