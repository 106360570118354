import { Button } from "@/components/ui/button";
import { reloadPage } from "@/utils";
import { cn } from "@/utils/styles";

type ErrorFeedbackProps = {
  title?: string;
  message: string;
  className?: string;
};

export const ErrorFeedback: React.FC<ErrorFeedbackProps> = ({ title, message, className }) => {
  return (
    <div
      data-testid="ErrorFeedback"
      className={cn("flex flex-1 flex-col items-center justify-center gap-6 p-10", className)}
    >
      <img src="/assets/images/error.png" className="h-[108px]" />

      <div className="flex flex-col gap-5 text-center">
        <div className="flex flex-col gap-1">
          <h4 className="title-h4 text-white">{title ? title : "Oops! Something went wrong"}</h4>
          <p className="subtext-small text-gray-dark-1000">{message}</p>
        </div>
        <div className="flex flex-row items-center justify-center">
          <Button
            variant={"outline"}
            onClick={() => {
              reloadPage();
            }}
          >
            Reload
          </Button>
        </div>
      </div>
    </div>
  );
};
