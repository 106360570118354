import { FileCoinIcon } from "@/components/Icons/FileCoinIcon";
import { IoIcon } from "@/components/Icons/IoIcon";
import { LudwigClusterIcon } from "@/components/Icons/LudwigClusterIcon";
import { NvidiaIcon } from "@/components/Icons/NvidiaIcon";
import { PlyTorchIcon } from "@/components/Icons/PlyTorchIcon";
import { RayClusterIcon } from "@/components/Icons/RayClusterIcon";
import { UnityIcon } from "@/components/Icons/UnityIcon";
import { UnrealIcon } from "@/components/Icons/Unrealcon";
import { executeAPIRequest } from "@/utils/api";
import {
  Cluster,
  ClusterDetail,
  ClusterDetailResponse,
  ClustersEarningsSummary,
  ClustersEarningsSummaryResponse,
  ClusterForm,
  ClusterHardware,
  ClusterHardwareInfo,
  ClusterHardwareInfoMap,
  ClusterHardwareInfoResponse,
  ClusterHardwareMap,
  ClusterResponse,
  ClusterStats,
  ClusterWorker,
  ClusterWorkerInfo,
  ClusterWorkerInfoResponse,
  ClusterWorkerResponse,
  CreateClusterData,
  CreateClusterDataFilteredResponse,
  CreateClusterDataResponse,
  PaginatedResponse,
  ClusterType,
  SupplyHardware,
  ClusterHourlyEarningsType,
  ClusterHourlyEarningsResponseType
} from "@/types";
import { toStandardisedDuration } from "@/utils/date";
import { create } from "zustand";
import { useUserStore } from "./user";
import { AppleIcon } from "@/components/Icons/AppleIcon";
import { AmdIcon } from "@/components/Icons/AmdIcon";
import { DEFAULT_PAGINATION_LIMIT } from "@/constants";
import { ucfirst } from "@/utils/string";
import { getHardware, getService, STATUS_COLORS } from "@/utils/mapping";
import { stringify } from "qs";
import { busyPercentage } from "@/utils/format";
import { LightningIcon } from "@/components/Icons/LightningIcon";
import { SpannerIcon } from "@/components/Icons/SpannerIcon";
import { InferenceIcon } from "@/components/Icons/InferenceIcon";
import { RenderNetworkIcon } from "@/components/Icons/RenderNetworkIcon";
import { filterNull, isEmpty } from "@/utils";
import { KubeflowIcon } from "@/components/Icons/KubeflowIcon";
import { Soc2HipAaIcon } from "@/components/Icons/Soc2HipAaIcon";
import { E2EEncryptedIcon } from "@/components/Icons/E2EEncryptedIcon";
import env from "@/env";
import { getMinHardware } from "@/utils/cluster";
import { filterProps } from "@/lib/utils";
import { objectWithkeys } from "@/utils/array";
import { BareMetalIcon } from "@/components/Icons/BareMetalIcon";

const CLUSTER_ATTRIBUTES_PAYLOAD_EMPTY = ["supplier", "hardware", "connectivityTier", "locations"];

const CLUSTER_ATTRIBUTES_HARDWARE_STEP = [
  "supplier",
  "connectivityTier",
  "locations",
  "securityCompliance",
  "service"
];

type UseClustersStoreProps = {
  apiCache: {
    [k: string]: unknown;
  };
  fetchClustersRequest: (options: {
    type?: string | null;
    status: string;
    page?: number;
    limit?: number;
    resource_name?: string;
  }) => Promise<{
    resultCount: number;
    results: Cluster[];
    statuses: string[];
  }>;
  fetchClustersBySearchQuery: (options: {
    type?: string;
    searchQuery?: string;
    timestamp?: number;
  }) => Promise<
    {
      id: string;
      name: string;
      url: string;
      status: string;
    }[]
  >;
  fetchClusterDetail: (options: { id: string; type?: string }) => Promise<ClusterDetail>;
  fetchClusterWorkerInfo: (options: { id: string; type?: string }) => Promise<ClusterWorkerInfo>;
  fetchClusterWorkers: (options: {
    id: string;
    type?: string;
    searchQuery: string;
  }) => Promise<ClusterWorker[]>;
  fetchFilteredCreateClusterDataResponse: (
    options: {
      type?: string;
    },
    payload: Partial<ClusterForm>
  ) => Promise<{
    responseWithFullPayload: CreateClusterDataFilteredResponse;
    listHardware?: SupplyHardware[];
  }>;
  fetchFilteredCreateClusterHardwareSupply: (
    payload: Partial<ClusterForm>
  ) => Promise<SupplyHardware[]>;
  requestBareMetal: (values: ClusterForm) => Promise<boolean>;
  fetchFilteredCreateClusterData: (
    options: {
      type?: string;
    },
    payload: Partial<ClusterForm>,
    createClusterData: CreateClusterData,
    clusterType?: ClusterType
  ) => Promise<CreateClusterData>;
  fetchCreateClusterData: (
    options: {
      type?: string;
    },
    payload?: Partial<ClusterForm>
  ) => Promise<CreateClusterData>;
  fetchMegaCreateClusterData: (
    options: {
      type?: string;
    },
    payload?: Partial<ClusterForm>
  ) => Promise<CreateClusterData>;
  getClusterOptions: (
    values: ClusterForm,
    createClusterData: CreateClusterData
  ) => { type: string; name?: string; content: React.ReactNode }[];
  createCluster: (type: string | undefined, values: ClusterForm) => Promise<string | undefined>;
  fetchClusterHardwareInfo: (values: Partial<ClusterForm>) => Promise<ClusterHardwareInfo | null>;
  fetchMegaClusterHardwareInfo: (
    values: Partial<ClusterForm>
  ) => Promise<ClusterHardwareInfo | null>;
  removeCluster: (id: string) => Promise<void>;
  archiveCluster: (id: string) => Promise<void>;
  renameCluster: (id: string, name: string) => Promise<void>;
  fetchClusterStats: (options: { isEnterprise: boolean }) => Promise<ClusterStats>;
  checkIfHeadnodesAvailable: () => Promise<number>;
  checkIfNameIsAvailable: (name: string, signal?: AbortController | undefined) => Promise<boolean>;
  extendClusterRental: (data: {
    clusterId: string;
    hourCount: number;
    currency: "iocoin" | "usdc";
    transaction: string;
    durationQty: number;
    durationType: "hourly" | "daily" | "weekly";
  }) => Promise<string>;
  fetchExtendClusterPrice: (
    id: string,
    durationQty: number,
    durationType: "hourly" | "daily" | "weekly"
  ) => Promise<{
    hourPrice: number;
    hourPriceInIocoin: number;
    transaction: string;
    conversionRate: number;
    cost?: number;
    currencyConversionFee?: number;
    ionetFee?: number;
    ionetFeePrecent?: number;
    totalCost?: number;
  }>;
  fetchClusterEarningsSummary: (options: { type: string }) => Promise<ClustersEarningsSummary[]>;
  fetchClusterHourlyEarnings: () => Promise<ClusterHourlyEarningsType[]>;
};

export const useClusterStore = create<UseClustersStoreProps>((_set, get) => ({
  apiCache: {},
  fetchClustersBySearchQuery: async ({ type = "cloud", searchQuery = "", timestamp = "" }) => {
    try {
      const userId = useUserStore.getState().userId;

      if (type === "explorer") {
        const response = await executeAPIRequest<{
          data: {
            cluster_id: string;
            status: string;
          }[];
          status: string;
        }>({
          method: "get",
          url: `/io-explorer/clusters/search?${stringify({
            cluster_id: searchQuery,
            timestamp
          })}`
        });

        return response.data
          .map(({ cluster_id, status }) => {
            return {
              id: cluster_id,
              name: cluster_id,
              url: `/explorer/clusters/${cluster_id}`,
              status
            };
          })
          .filter(({ id }) => {
            return !!id;
          });
      } else {
        const response = await executeAPIRequest<{
          data: PaginatedResponse<{
            resources: ClusterResponse[];
            total_resources: number;
            statuses: string[];
          }>;
          status: string;
        }>({
          method: "get",
          url: `/io-cloud/users/${userId}/resources?${stringify({
            resource_name: searchQuery,
            resource_type:
              type === "kubernetes" ? "k8s_cluster" : type === "mega" ? "cluster" : type,
            status: "all",
            is_mega_cluster: type === "mega"
          })}`
        });

        return response.data.resources
          .map(({ resource_id, resource_name, status }) => {
            return {
              id: resource_id,
              name: resource_name,
              url: getClusterDetailsRoute({
                tool: "cloud",
                clusterType: type,
                clusterId: resource_id
              }),
              status
            };
          })
          .filter(({ id }) => {
            return !!id;
          });
      }
    } catch (e) {
      console.log(e);

      return [];
    }
  },
  checkIfHeadnodesAvailable: async () => {
    const response = await executeAPIRequest<{
      data: { no_of_headnodes: number };
      status: string;
    }>({
      method: "get",
      url: `/io-cloud/clusters/check-headnode-availability`
    });

    return response.data.no_of_headnodes;
  },
  checkIfNameIsAvailable: async (name: string, abortController) => {
    try {
      const response = await executeAPIRequest<boolean>({
        method: "post",
        url: `/io-cloud/clusters/check_cluster_name_availability ?${stringify({
          cluster_name: name
        })}`,
        options: {
          abortController
        }
      });

      return response !== false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  createCluster: async (type: string | undefined, values: ClusterForm) => {
    const hourQuantity =
      values.durationQuantity !== undefined
        ? getClusterHourCount(values.durationQuantity, values.durationType)
        : undefined;

    const urlMap = {
      cluster: "deploy_cluster",
      kubernetes: "deploy-k8s-cluster",
      mega: "deploy_mega_cluster"
    };

    const data = (() => {
      if (type === "kubernetes") {
        return {
          hardware_id: values.hardware,
          hardware_qty: values.hardwareQuantity,
          hours_qty: hourQuantity,
          k8s_service_ids: values.k8sOperators ? [1] : [],
          supplier_id: 1,
          user_id: useUserStore.getState().userId,
          resource_private_name: values.name,
          security_soc2: true,
          connectivity_tier: 0,
          location_id: [41],
          currency: values.currency,
          ...(values.sshKey
            ? {
                ssh_public_key_name: values.sshKeyName,
                ssh_public_key: values.sshKey
              }
            : {}),
          package: getClusterPackageName(values)
        };
      }

      if (type === "mega") {
        return {
          hours_qty: hourQuantity,
          service_id: 3,
          supplier_id: values.supplier,
          connectivity_tier: values.connectivityTier,
          resource_private_name: values.name,
          security_soc2: false,
          device_type: values.hardwareType,
          package: getClusterPackageName(values),
          currency: values.currency,
          duration_qty: values.durationQuantity,
          duration_type: values.durationType
        };
      }

      return {
        hardware_id: values.hardware,
        hardware_qty: values.hardwareQuantity,
        hours_qty: hourQuantity,
        location_id: values.locations,
        service_id: values.service,
        supplier_id: values.supplier,
        security_soc2: values.securityCompliance === "SOC2/HIPAA",
        connectivity_tier: values.connectivityTier,
        user_id: useUserStore.getState().userId,
        resource_private_name: values.name,
        package: getClusterPackageName(values),
        currency: values.currency,
        duration_qty: values.durationQuantity,
        duration_type: values.durationType
      };
    })();

    const response = await executeAPIRequest<{
      data: {
        cluster_id: string;
      };
      status: string;
    }>({
      method: "post",
      url: `/io-cloud/clusters/${urlMap[(type as keyof typeof urlMap) || "cluster"]}`,
      options: {
        data,
        ...(values.hardwareInfo?.transaction
          ? {
              headers: {
                transaction: values.hardwareInfo?.transaction
              }
            }
          : {}),
        timeout: env.API_DEPLOY_CLUSTER_TIMEOUT
      }
    });

    return response.data.cluster_id;
  },
  getClusterOptions: (values: ClusterForm, createClusterData: CreateClusterData) => {
    const result: { type: string; name?: string; content: React.ReactNode }[] = [];

    if (values.hardware) {
      const hardware = createClusterData.hardwareList.find(({ id }) => id === values.hardware);

      if (hardware) {
        result.push({
          type: "hardware",
          name: hardware.hardwareName,
          content: (
            <>
              <div className="label-medium text-gray">Processor</div>
              <div
                className={`flex h-[12px] w-[12px] items-center justify-center bg-black text-white ${hardware.hardwareManufacturerColor}`}
              >
                {hardware.hardwareManufacturerIcon ? (
                  <hardware.hardwareManufacturerIcon className="h-[5px] max-w-[8px] text-white" />
                ) : null}
              </div>
              <div>{hardware.hardwareName}</div>
            </>
          )
        });
      }
    }

    // if (values.locations.length > 0) {
    //   const locations = createClusterData.locations.filter(
    //     ({ id }) => values.locations.indexOf(id) > -1
    //   );

    //   result.push({
    //     value: "location",
    //     content: (
    //       <>
    //         <div className="label-medium text-gray	">Locations</div>
    //         <div>{locations.map(({ name }) => name).join(", ")}</div>
    //       </>
    //     )
    //   });
    // }

    if (values.hardwareQuantity) {
      result.push({
        type: "processorQuantity",
        content: (
          <>
            <div className="label-medium text-gray	">{values.hardwareType.toUpperCase()}s</div>
            <div>{values.hardwareQuantity}</div>
          </>
        )
      });
    }

    if (values.clusterType) {
      result.push({
        type: "clusterType",
        name: values.clusterType,
        content: (
          <>
            <div className="label-medium text-gray	">Cluster Type</div>
            <div>{ucfirst(values.clusterType)}</div>
          </>
        )
      });
    }

    if (values.supplier) {
      const supplier = createClusterData.suppliers.find(({ id }) => id === values.supplier);

      if (supplier) {
        result.push({
          type: "supplier",
          name: supplier.name,
          content: (
            <>
              <div className="label-medium text-gray	">Supplier</div>
              <div>{supplier.name}</div>
            </>
          )
        });
      }
    }

    if (values.connectivityTier) {
      const connectivityTier = createClusterData.connectivityTiers.find(
        ({ id }) => values.connectivityTier === id
      );

      if (connectivityTier) {
        result.push({
          type: "connectivityTier",
          name: connectivityTier.name,
          content: (
            <>
              <div className="label-medium text-gray	">Connectivity Tier</div>
              <div>{connectivityTier.name}</div>
            </>
          )
        });
      }
    }

    if (values.service) {
      const service = createClusterData.services.find(({ id }) => id === values.service);

      result.push({
        type: "service",
        name: service?.name,
        content: (
          <>
            <div className="label-medium text-gray	">Cluster Base Image</div>
            <div>{service?.name}</div>
          </>
        )
      });
    }

    if (values.k8sOperators) {
      const service = createClusterData.services.find(({ id }) => id === values.k8sOperators);

      result.push({
        type: "k8sOperators",
        name: service?.name,
        content: (
          <>
            <div className="label-medium text-gray	">K8s Operators</div>
            <div>{service?.name}</div>
          </>
        )
      });
    }

    if (values.securityCompliance) {
      result.push({
        type: "securityCompliance",
        name: values.securityCompliance,
        content: (
          <>
            <div className="label-medium text-gray	">Security Compliance</div>
            <div>{ucfirst(values.securityCompliance)}</div>
          </>
        )
      });
    }

    result.push({
      type: "paymentFrequency",
      content: (
        <>
          <div className="label-medium text-gray	">Billing Type</div>
          <div>{ucfirst(values.durationType)}</div>
        </>
      )
    });

    if (values.durationQuantity !== undefined) {
      result.push({
        type: "billingDuration",
        content: (
          <>
            <div className="label-medium text-gray	">Billing Duration</div>
            <div>{getClusterHourCount(values.durationQuantity, values.durationType)} hours</div>
          </>
        )
      });
    }

    return result;
  },
  fetchClustersRequest: async (options) => {
    const {
      type = "cluster",
      status = "all",
      page = 1,
      resource_name,
      limit = DEFAULT_PAGINATION_LIMIT
    } = options;

    const response = await executeAPIRequest<{
      data: PaginatedResponse<{
        clusters?: ClusterResponse[];
        resources?: ClusterResponse[];
        total_clusters?: number;
        total_resources?: number;
        statuses: string[];
      }>;
      status: string;
    }>({
      method: "get",
      url:
        type === "explorer"
          ? `/io-explorer/clusters/status?${stringify({
              ...(status != "all" ? { status } : {}),
              page: page,
              page_size: limit
            })}`
          : `/io-cloud/users/${useUserStore.getState().userId}/resources?${stringify({
              status,
              archived: status === "archived",
              page: page,
              page_size: limit,
              resource_name,
              ...(type !== null
                ? {
                    resource_type:
                      type === "kubernetes" ? "k8s_cluster" : type === "mega" ? "cluster" : type,
                    is_mega_cluster: type === "mega"
                  }
                : {})
            })}`
    });

    const { data } = response;

    const resultCount = (
      type === "explorer" ? data.total_clusters : data.total_resources
    ) as number;
    const results = (
      (type === "explorer" ? data.clusters : data.resources) as ClusterResponse[]
    ).map((cluster) => {
      return {
        ...normaliseCluster(cluster),
        ...(type
          ? {
              type
            }
          : {})
      };
    });

    return {
      resultCount,
      results,
      statuses: data.statuses
    };
  },
  fetchClusterDetail: async ({ id, type }) => {
    const response = await executeAPIRequest<{
      data: ClusterDetailResponse;
      status: string;
    }>({
      method: "get",
      url:
        type === "explorer"
          ? `/io-explorer/clusters/${id}/details`
          : `/io-cloud/clusters/${id}/details`
    });

    return normaliseClusterDetail(response.data);
  },
  fetchClusterWorkerInfo: async ({ id, type }) => {
    const response = await executeAPIRequest<{
      data: ClusterWorkerInfoResponse;
      status: string;
    }>({
      method: "get",
      url: `${
        type === "explorer"
          ? `/io-explorer/clusters/${id}/workers`
          : `/io-cloud/clusters/${id}/workers`
      }`
    });

    return normaliseClusterWorkerInfo(response.data);
  },
  fetchClusterWorkers: async ({ id, type, searchQuery }) => {
    try {
      const response = await executeAPIRequest<{
        data: ClusterWorkerResponse[];
        status: string;
      }>({
        method: "get",
        url: `/${type === "explorer" ? "io-explorer" : "io-cloud"}/clusters/${id}/workers?${stringify(
          {
            device_id: searchQuery
          }
        )}`
      });
      return (Array.isArray(response.data) ? response.data : [response.data]).map(
        (clusterWorker) => {
          return normaliseClusterWorker(clusterWorker);
        }
      );
    } catch (e) {
      return [];
    }
  },
  requestBareMetal: async (values: ClusterForm) => {
    const data = {
      hardware_id: Number(values.hardware),
      hardware_qty: Number(values.hardwareQuantity),
      // hours_qty: hourQuantity,
      // ssh_key_name: "no-key", //values.sshKeyName,
      ssh_pub_key: values.sshKey,
      location_id: Number(values.locations[0]),
      service_id: Number(values.service),
      supplier_id: Number(values.supplier),
      security_soc2: 1, //values.securityCompliance === "SOC2/HIPAA",
      connectivity_tier: Number(values.connectivityTier),
      resource_private_name: values.name,
      currency: values.currency,
      duration_qty: Number(values.durationQuantity),
      duration_type: values.durationType,
      estimated_cost: values.hardwareInfo?.totalCost
    };

    try {
      const response = await executeAPIRequest<boolean>({
        method: "post",
        url: "/io-cloud/clusters/request_baremetal",
        options: {
          data,
          ...(values.hardwareInfo?.transaction
            ? {
                headers: {
                  transaction: values.hardwareInfo?.transaction
                }
              }
            : {}),
          timeout: env.API_DEPLOY_CLUSTER_TIMEOUT
        }
      });

      return response;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  fetchFilteredCreateClusterHardwareSupply: async (payload) => {
    const response = await executeAPIRequest<{
      data: SupplyHardware[];
      status: string;
    }>({
      method: "get",
      url: `/io-cloud/clusters/hardware-supply?${stringify(
        filterNull(
          payload
            ? {
                security_soc2: payload.securityCompliance === "SOC2/HIPAA",
                supplier_id: payload.supplier,
                service_id: payload.service,
                hardware_id: payload.hardware,
                connectivity_tier: payload.connectivityTier,
                ...(payload.locations?.length || 0 > 0
                  ? {
                      location_ids: `[${payload.locations?.join(",")}]`
                    }
                  : {})
              }
            : {}
        )
      )}`
    });

    return response.data;
  },
  fetchFilteredCreateClusterDataResponse: async ({ type }, payload) => {
    const { apiCache } = get();
    const cacheKey = `${type}_${JSON.stringify(payload)}`;
    let listHardware: SupplyHardware[] = [];
    const { fetchFilteredCreateClusterHardwareSupply } = get();

    const payloadKeys = Object.keys(filterProps(payload));
    if (
      payloadKeys.length === CLUSTER_ATTRIBUTES_HARDWARE_STEP.length &&
      payloadKeys.every(
        (value: string, index: number) => value === CLUSTER_ATTRIBUTES_HARDWARE_STEP[index]
      )
    ) {
      listHardware = await fetchFilteredCreateClusterHardwareSupply(payload);
    }

    if (apiCache && cacheKey in apiCache) {
      return {
        responseWithFullPayload: apiCache[cacheKey] as CreateClusterDataFilteredResponse,
        listHardware
      };
    }

    const response = await executeAPIRequest<{
      data: CreateClusterDataFilteredResponse;
      status: string;
    }>({
      method: "get",
      url: `/io-cloud/clusters/${
        type === "kubernetes" ? `create-k8s-cluster-data` : `create-cluster-data`
      }?${stringify(
        filterNull(
          payload
            ? {
                supplier_id: payload.supplier,
                hardware_id: payload.hardware,
                connectivity_tier: payload.connectivityTier,
                ...(payload.locations?.length || 0 > 0
                  ? {
                      location_ids: `[${payload.locations?.join(",")}]`
                    }
                  : {})
              }
            : {}
        )
      )}`
    });

    apiCache[cacheKey] = response.data;

    return { responseWithFullPayload: response.data, listHardware };
  },
  fetchFilteredCreateClusterData: async (
    { type },
    payload,
    createClusterData,
    clusterType = ClusterType.RAY
  ) => {
    const { fetchFilteredCreateClusterDataResponse } = get();
    const isEmptyPayload =
      Object.values(objectWithkeys(payload, CLUSTER_ATTRIBUTES_PAYLOAD_EMPTY)).filter(
        (value) => !isEmpty(value)
      ).length === 0;
    let responseWithFullPayload: CreateClusterDataFilteredResponse | null = null;
    let listHardware: SupplyHardware[] = [];

    if (!isEmptyPayload) {
      const response = await fetchFilteredCreateClusterDataResponse({ type }, payload);
      if (response) {
        responseWithFullPayload = response.responseWithFullPayload;
        listHardware = response?.listHardware ?? [];
      }
    }

    const availableOptionMap = {
      connectivityTiers: {},
      hardwares: {},
      suppliers: {},
      locations: {},
      securityCompliances: {
        "End-to-End Encrypted": true
      }
    } as {
      connectivityTiers: {
        [k: string]: boolean;
      };
      hardwares: {
        [k: string]: boolean;
      };
      suppliers: {
        [k: string]: boolean;
      };
      locations: {
        [k: string]: boolean;
      };
      securityCompliances: {
        [k: string]: boolean;
      };
    };

    if (responseWithFullPayload) {
      for (const item of responseWithFullPayload) {
        availableOptionMap.suppliers[`${item.supplier_id}`] = true;
        if (item.security_soc2) {
          availableOptionMap.securityCompliances["SOC2/HIPAA"] = true;
        }
        availableOptionMap.connectivityTiers[`${item.connectivity_tier}`] = true;
        availableOptionMap.hardwares[`${item.hardware_id}`] = true;
        availableOptionMap.locations[`${item.location_id}`] = true;
      }
    } else {
      for (const item of createClusterData.hardwareList) {
        availableOptionMap.hardwares[`${item.id}`] = true;
      }
      for (const item of createClusterData.locations) {
        availableOptionMap.locations[`${item.id}`] = true;
      }
      for (const item of createClusterData.connectivityTiers) {
        availableOptionMap.connectivityTiers[`${item.id}`] = true;
      }
    }

    const newCreateClusterData = createClusterData;

    const attributes = ["connectivityTiers", "locations", "securityCompliances"];

    for (const attribute of attributes) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const list = (newCreateClusterData as any)[attribute] as {
        id: string;
        unavailable: boolean;
      }[];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (newCreateClusterData as any)[attribute] = list.map((item) => {
        const disabled =
          (!isEmptyPayload &&
            !(item.id in availableOptionMap[attribute as keyof typeof availableOptionMap])) ||
          item.unavailable === false;

        return {
          ...item,
          disabled
        };
      });
    }

    newCreateClusterData.hardwareList = newCreateClusterData.hardwareList.map((hardware) => {
      const disabled = !isEmptyPayload && !(hardware.id in availableOptionMap.hardwares);
      const available =
        listHardware.find((item: SupplyHardware) => String(item.hardware_id) === hardware.id)
          ?.quantity_available || 0;

      return {
        ...hardware,
        available,
        disabled: available < getMinHardware(clusterType) ? true : disabled
      };
    });

    return newCreateClusterData;
  },
  fetchMegaCreateClusterData: async () => {
    const { fetchCreateClusterData } = get();

    return await fetchCreateClusterData({ type: "cluster" });
  },
  fetchCreateClusterData: async ({ type = "cluster" }) => {
    const { fetchClusterHardwareInfo } = get();

    const hardwareInfoResponse = await fetchClusterHardwareInfo({
      securityCompliance: "SOC2/HIPAA"
    });

    const response = await executeAPIRequest<{
      data: CreateClusterDataResponse;
      status: string;
    }>({
      method: "get",
      url: `/io-cloud/clusters/${
        type === "kubernetes" ? "create-k8s-cluster-data" : "create-cluster-data"
      }`
    });

    return {
      ...normaliseCreateClusterData(type, {
        ...response.data,
        securitySoc2Enabled: (hardwareInfoResponse?.quantity || 0) > 0
      }),
      currencyConversionFeePrecent: hardwareInfoResponse?.currencyConversionFeePrecent,
      ionetFeePrecent: hardwareInfoResponse?.ionetFeePrecent
    };
  },
  fetchClusterHardwareInfo: async (values: Partial<ClusterForm>) => {
    const response = await executeAPIRequest<{
      data: ClusterHardwareInfoResponse;
      status: string;
    }>({
      method: "get",
      url: `/io-cloud/clusters/hardware-qty-price?${stringify(
        filterNull({
          supplier_id: values.supplier,
          hardware_id: values.hardware,
          security_soc2: values.securityCompliance === "SOC2/HIPAA",
          connectivity_tier: values.connectivityTier,
          service_id: values.service,
          ...(values.durationType ? { duration_type: values.durationType } : {}),
          ...(values.durationQuantity ? { duration_qty: values.durationQuantity } : {}),
          ...(values.durationQuantity ? { duration_qty: values.durationQuantity } : {}),
          ...(values.hardwareQuantity ? { hardware_qty: values.hardwareQuantity } : {}),
          ...(values.currency ? { currency: values.currency } : {}),
          ...(values.locations?.length || 0 > 0
            ? {
                location_id: `[${values.locations?.join(",")}]`
              }
            : {})
        })
      )}`
    });
    return normaliseClusterHardwareInfo(response.data);
  },
  fetchMegaClusterHardwareInfo: async (values: Partial<ClusterForm>) => {
    const response = await executeAPIRequest<{
      status: string;
      data: {
        total_hardware_qty: number;
        avg_price: number;
        avg_iocoin: number;
        conversion_rate: number;
        currency_conversion_fee_percent: number;
        ionet_fee_percent: number;
        transaction: string;
        cost?: number;
        currency_conversion_fee?: number;
        ionet_fee?: number;
        total_cost?: number;
      };
    }>({
      method: "get",
      url: `/io-cloud/clusters/create-mega-cluster-data?${stringify(
        filterNull({
          supplier_id: values.supplier,
          security_soc2: values.securityCompliance === "SOC2/HIPAA",
          service_id: 3,
          connectivity_tier: values.connectivityTier,
          device_type: values.hardwareType,
          duration_type: values.durationType,
          duration_qty: values.durationQuantity,
          currency: values.currency
        })
      )}`
    });

    const { data } = response;

    return {
      averagePerCard: data.avg_price,
      ioAveragePerCard: data.avg_iocoin,
      ionetFeePrecent: data.ionet_fee_percent,
      currencyConversionFeePrecent: data.currency_conversion_fee_percent,
      conversionRate: data.conversion_rate,
      transaction: data.transaction,
      quantity: data.total_hardware_qty,
      cost: data.cost,
      currencyConversionFee: data.currency_conversion_fee,
      ionetFee: data.ionet_fee,
      totalCost: data.total_cost
    } as ClusterHardwareInfo;
  },
  removeCluster: async (id: string) => {
    await executeAPIRequest<{
      data: ClusterHardwareInfoResponse;
      status: string;
    }>({
      method: "put",
      url: `/io-cloud/clusters/destroy-cluster?cluster_id=${id}`
    });
  },
  archiveCluster: async (id: string) => {
    await executeAPIRequest<{
      data: ClusterHardwareInfoResponse;
      status: string;
    }>({
      method: "put",
      url: `/io-cloud/clusters/${id}`
    });
  },
  renameCluster: async (id, name) => {
    await executeAPIRequest<{
      data: unknown;
      status: string;
    }>({
      method: "put",
      url: `/io-cloud/clusters/${id}/update-name`,
      options: {
        data: {
          cluster_name: name
        }
      }
    });
  },
  fetchClusterStats: async ({ isEnterprise = false }) => {
    const response = await executeAPIRequest<{
      data: ClusterStats;
      status: string;
    }>({
      method: "get",
      url: isEnterprise ? "" : "/io-explorer/network/info/clusters"
    });

    return response.data;
  },
  extendClusterRental: async ({
    clusterId,
    hourCount,
    currency,
    transaction,
    durationQty,
    durationType
  }) => {
    const response = await executeAPIRequest<{
      message: string;
      status: string;
    }>({
      method: "post",
      url: `/io-cloud/clusters/${clusterId}/extend`,
      options: {
        headers: {
          transaction
        },
        data: {
          hours_qty: hourCount,
          currency,
          duration_qty: durationQty,
          duration_type: durationType
        }
      }
    });

    return response.message;
  },
  fetchExtendClusterPrice: async (id, durationQty, durationType) => {
    const response = await executeAPIRequest<{
      data: {
        hour_price: number;
        hour_price_in_iocoin: number;
        conversion_rate: number;
        transaction: string;
        cost?: number;
        currency_conversion_fee?: number;
        ionet_fee?: number;
        ionet_fee_percent?: number;
        total_cost?: number;
      };
      status: string;
    }>({
      method: "get",
      url: `/io-cloud/clusters/${id}/get-extension-price?duration_qty=${durationQty}&duration_type=${durationType}`
    });

    return {
      hourPrice: response.data.hour_price,
      hourPriceInIocoin: response.data.hour_price_in_iocoin,
      transaction: response.data.transaction,
      conversionRate: response.data.conversion_rate,
      cost: response.data.cost,
      currencyConversionFee: response.data.currency_conversion_fee,
      ionetFee: response.data.ionet_fee,
      ionetFeePrecent: response.data.ionet_fee_percent,
      totalCost: response.data.total_cost
    };
  },
  fetchClusterEarningsSummary: async (options) => {
    const { type = "explorer" } = options;

    if (type === "explorer") {
      const response = await executeAPIRequest<{
        status: string;
        data: ClustersEarningsSummaryResponse[];
      }>({
        method: "get",
        url: "/io-explorer/network/info/cluster/total-earnings-summary"
      });

      return response.data.map((earning: ClustersEarningsSummaryResponse) => {
        return {
          dateMonth: earning.date_month,
          totalEarnings: earning.total_earnings,
          dailyEarnings: earning.daily_earnings
        };
      }) as ClustersEarningsSummary[];
    }

    return [];
  },
  fetchClusterHourlyEarnings: async () => {
    const response = await executeAPIRequest<{
      status: string;
      data: ClusterHourlyEarningsResponseType[];
    }>({
      method: "get",
      url: "/io-explorer/network/info/cluster/today-hourly-earnings"
    });

    return normaliseClusterHourlyEarnings(response.data);
  }
}));

const normaliseClusterDetail = (result: ClusterDetailResponse) => {
  const brandNames = (result.brand_info || []).filter(({ brand_name }) => {
    return !!brand_name;
  });
  const hardware = getHardware(brandNames[0]?.brand_name);
  const hardwares = brandNames.map(({ brand_name }) => {
    return getHardware(brand_name);
  });

  const status = STATUS_MAP[`${result.status?.toLowerCase()}` as keyof typeof STATUS_MAP];

  return {
    amountPaid: result.amount_paid || 0,
    amountRefunded: result.amount_refunded || 0,
    baseTierName: result.base_tier_name,
    clusterId: result.cluster_id,
    clusterName: result.cluster_name,
    computeHoursRemaining: toStandardisedDuration(result.compute_hours_remaining),
    computeHoursServed: toStandardisedDuration(result.compute_hours_served),
    downloadSpeedMbps: result.download_speed_mbps,
    finishedAt: result.finished_at,
    hardwareQuantity: result.hardware_info
      ? result.hardware_info.quantity
      : result.hardware_quantity,
    hardwareName: result.hardware_info?.hardware_name,
    percentCompleted: result.percent_completed,
    rayDashboardUrl: result.ray_dashboard_url,
    securityCompliance: result.security_compliance === "true",
    startedAt: result.started_at,
    uploadSpeedMbps: result.upload_speed_mbps,
    vscodeUrl: result.vscode_url,
    jupyterUrl: result.jupyter_url,
    locations: result.locations
      ? result.locations.map((location) => {
          return {
            value: `${location.location_id}`,
            label: location.location_name,
            code: location.iso2
          };
        })
      : [],
    dashboardAuthKey: result.dashboard_auth_key,
    runningFor: toStandardisedDuration(result.running_for),
    k8sFile: result.k8s_file,
    currency: result.currency,
    ...status,
    ...hardware,
    hardwares
  } as ClusterDetail;
};

export const normaliseClusterWorkerInfo = (result: ClusterWorkerInfoResponse) => {
  return {
    totalCpu: result.total_cpu,
    totalGpu: result.total_gpu,
    items: result.workers_info.map((worker) => {
      return normaliseClusterWorker(worker);
    })
  } as ClusterWorkerInfo;
};

const normaliseClusterWorker = (result: ClusterWorkerResponse) => {
  const hardware = getHardware(result.brand_name || "");

  return {
    id: `${result.device_id}`,
    deviceId: result.device_id,
    uptimePercent: result.uptime_percent,
    hardwareName: result.hardware_name,
    hardwareQuantity: result.hardware_quantity,
    status: result.status,
    ...hardware
  } as ClusterWorker;
};

const normaliseCluster = (result: ClusterResponse) => {
  const status = STATUS_MAP[`${result.status.toLowerCase()}` as keyof typeof STATUS_MAP];
  const { brand_name, brand_info } = result;
  const brandNames = (
    Array.isArray(brand_info)
      ? brand_info
      : [...(brand_info ? [brand_info] : brand_name ? [{ brand_name }] : [])]
  ).filter(({ brand_name }) => {
    return !!brand_name;
  });
  const hardware = getHardware(brandNames[0]?.brand_name);
  const hardwares = brandNames.map(({ brand_name }) => {
    return getHardware(brand_name);
  });
  const id = result.resource_id || result.cluster_id;

  return {
    id,
    resourceType: result.resource_type,
    name: result.resource_name,
    computeHoursRemaining: toStandardisedDuration(result.compute_hours_remaining),
    computeHoursServed: toStandardisedDuration(result.compute_hours_served),
    hardwareName: result.hardware_name,
    hardwareQuantity: result.hardware_quantity,
    percentCompleted: result.percent_completed,
    hasIncident: false,
    ...status,
    jupyterUrl: result.jupyter_url,
    vscodeUrl: result.vscode_url,
    rayDashboardUrl: result.ray_dashboard_url,
    startedAt: result.started_at,
    statusMessage: status?.statusMessage
      .split("${percentCompleted}")
      .join(`${result.percent_completed}`),
    ...hardware,
    hardwares,
    href: `/cloud/clusters/${id}`
  } as Cluster;
};

export const STATUS_ORDERS = [
  "archived",
  "running",
  "completed",
  "deploying",
  "deployment requested",
  "unpaid",
  "failed",
  "termination requested",
  "terminated",
  "destroyed"
];

export const STATUS_MAP: {
  [k: string]: {
    status: string;
    statusLabel: string;
    statusColor: string;
    statusMessage: string;
    statusValue: string;
  };
} = {
  running: {
    status: "running",
    statusLabel: "Running",
    statusColor: STATUS_COLORS["running"].colorClass,
    statusMessage: "${percentCompleted}% Completed",
    statusValue: "running"
  },
  completed: {
    status: "completed",
    statusLabel: "Completed",
    statusColor: STATUS_COLORS["completed"].colorClass,
    statusMessage: "${percentCompleted}% Completed",
    statusValue: "completed"
  },
  deploying: {
    status: "deploying",
    statusLabel: "Deploying",
    statusColor: STATUS_COLORS["running"].colorClass,
    statusMessage: "Deploying",
    statusValue: "deploying"
  },
  "deployment requested": {
    status: "deploymentRequested",
    statusLabel: "Deployment Requested",
    statusColor: STATUS_COLORS["deploymentRequested"].colorClass,
    statusMessage: "Deployment Requested",
    statusValue: "deployment requested"
  },
  unpaid: {
    status: "unpaid",
    statusLabel: "Unpaid",
    statusColor: STATUS_COLORS["unpaid"].colorClass,
    statusMessage: "Unpaid",
    statusValue: "unpaid"
  },
  failed: {
    status: "failed",
    statusLabel: "Failed",
    statusColor: STATUS_COLORS["failed"].colorClass,
    statusMessage: "Failed",
    statusValue: "failed"
  },
  "termination requested": {
    status: "terminationRequested",
    statusLabel: "Termination Requested",
    statusColor: STATUS_COLORS["terminationRequested"].colorClass,
    statusMessage: "Termination Requested",
    statusValue: "termination requested"
  },
  terminated: {
    status: "terminated",
    statusLabel: "Terminated",
    statusColor: STATUS_COLORS["terminated"].colorClass,
    statusMessage: "Terminated",
    statusValue: "terminated"
  },
  destroyed: {
    status: "destroyed",
    statusLabel: "Destroyed",
    statusColor: STATUS_COLORS["destroyed"].colorClass,
    statusMessage: "Destroyed",
    statusValue: "destroyed"
  },
  archived: {
    status: "archived",
    statusLabel: "Archived",
    statusColor: STATUS_COLORS["archived"].colorClass,
    statusMessage: "Archived",
    statusValue: "archived"
  }
};

export const MANUFACTURER_MAP = {
  apple: {
    icon: AppleIcon,
    color: "bg-gray"
  },
  amd: {
    icon: AmdIcon,
    color: "bg-red"
  },
  nvidia: {
    icon: NvidiaIcon,
    color: "bg-green"
  }
};

export const getClusterHourCount = (paymentQuantity: number, duration: string) => {
  switch (duration) {
    case "hourly":
      return paymentQuantity;
    case "daily":
      return 24 * paymentQuantity;
    case "weekly":
      return 7 * 24 * paymentQuantity;
  }
};

const normaliseClusterHardwareInfo = (result: ClusterHardwareInfoResponse) => {
  if (typeof result.qty !== "number") {
    return {
      averagePerCard: 0,
      ioAveragePerCard: 0,
      conversionRate: 1,
      quantity: 0,
      transaction: "",
      availableQuantity: [],
      currencyConversionFeePrecent: result.currency_conversion_fee_precent,
      ionetFeePrecent: result.ionet_fee_precent,
      cost: 0,
      currencyConversionFee: 0,
      ionetFee: 0,
      totalCost: 0
    };
  }

  const transformedArray: ClusterHardwareInfoMap[] = [];

  for (const key in result.available_quantity) {
    // eslint-disable-next-line no-prototype-builtins
    if (result.available_quantity.hasOwnProperty(key)) {
      const label = String(parseInt(key, 10));
      const value = String(parseInt(key, 10));

      transformedArray.push({ label, value });
    }
  }
  return {
    averagePerCard: result.avg,
    ioAveragePerCard: result.avg_iocoin,
    quantity: result.qty,
    availableQuantity: transformedArray,
    currencyConversionFeePrecent: result.currency_conversion_fee_precent,
    conversionRate: result.conversion_rate,
    ionetFeePrecent: result.ionet_fee_precent,
    transaction: result.transaction,
    cost: result.cost,
    currencyConversionFee: result.currency_conversion_fee,
    ionetFee: result.ionet_fee,
    totalCost: result.total_cost
  } as ClusterHardwareInfo;
};

const CONNECTIVITY_TIER_OPTIONS = [
  {
    id: "1",
    name: "Low Speed",
    downloadSpeedMbps: 100,
    uploadSpeedMbps: 75
  },
  {
    id: "2",
    name: "Medium Speed",
    downloadSpeedMbps: 300,
    uploadSpeedMbps: 150
  },
  {
    id: "3",
    name: "High Speed",
    downloadSpeedMbps: 100,
    uploadSpeedMbps: 75
  },
  {
    id: "4",
    name: "Ultra High Speed",
    downloadSpeedMbps: 1600,
    uploadSpeedMbps: 1200
  }
];

const SECURITY_COMPLIANCE_OPTIONS = [
  {
    id: "SOC2/HIPAA",
    name: "SOC2/HIPAA",
    icon: Soc2HipAaIcon
  },
  {
    id: "End-to-End Encrypted",
    name: "E2E Encrypted",
    icon: E2EEncryptedIcon
  }
];

const KUBERNETES_SUPPLIER_OPTIONS = [
  {
    id: "1",
    name: "io.net",
    icon: <IoIcon className="h-[14px]" />,
    description:
      "The io.net supply offers a cutting-edge global networking infrastructure on a diverse selection of enterprise-grade GPU models, all of which meet the highest standards of security compliance. However, this comes at a premium cost",
    busyPercent: 0,
    disabled: false
  },
  {
    id: "3",
    name: "Filecoin",
    icon: <FileCoinIcon className="h-[13px]" />,
    description: "Filecoin Nodes are suitable for Data intensive workloads and massive size models",
    busyPercent: 0,
    disabled: true
  }
] as {
  id: string;
  name: string;
  icon: React.ReactNode;
  description: string;
  busyPercent: number;
  disabled: boolean;
  validate: (values: Partial<ClusterForm>) => boolean;
}[];

const SUPPLIER_OPTIONS = [
  {
    id: "1",
    name: "io.net",
    icon: <IoIcon className="h-[14px] w-[14px]" />,
    description:
      "Render Network Nodes are versatile, designed to handle both light workloads and tasks that require massive distribution.",
    busyPercent: 0
  },
  {
    id: "2",
    name: "Render Network",
    icon: <RenderNetworkIcon className="h-[18px]" />,
    description:
      "Render Network Nodes are suitable for light workloads or massively distributed tasks",
    busyPercent: 0,
    validate: (values: Partial<ClusterForm>) => {
      if (values.sustainable) {
        return false;
      }
      if (
        values.nvLink &&
        values.clusterType &&
        ["general", "inference"].indexOf(values.clusterType) > -1
      ) {
        return false;
      }
      if (values.clusterType && ["train"].indexOf(values.clusterType) > -1) {
        return false;
      }
      return true;
    }
  },
  {
    id: "3",
    name: "Filecoin",
    icon: <FileCoinIcon className="h-[13px]" />,
    description:
      "Filecoin Nodes are suitable for Data intensive workloads and massive size models ",
    busyPercent: 0
  }
] as {
  id: string;
  name: string;
  icon: React.ReactNode;
  description: string;
  busyPercent: number;
  disabled: boolean;
  validate: (values: Partial<ClusterForm>) => boolean;
}[];

export const SERVICE_OPTIONS = [
  {
    description: "",
    icon: RayClusterIcon,
    id: "3",
    name: "Ray App",
    disabled: false
  },
  {
    description: "",
    icon: PlyTorchIcon,
    id: "1",
    name: "Pytorch FSDP",
    disabled: false
  },
  {
    description: "",
    icon: LudwigClusterIcon,
    id: "4",
    name: "ludwig",
    disabled: false
  },
  {
    description: "",
    icon: IoIcon,
    id: "5",
    name: "IO Native App",
    disabled: false
  },
  {
    description: "",
    icon: UnrealIcon,
    id: "6",
    name: "Unreal Engine 5",
    disabled: false
  },
  {
    description: "",
    icon: UnityIcon,
    id: "7",
    name: "Unity Streaming",
    disabled: false
  }
];

export const BARE_METAL_SERVICE_OPTIONS = [
  {
    description: "",
    icon: BareMetalIcon,
    id: "3", //"8",
    name: "Bare Metal",
    disabled: false
  }
];

export const KUBERNETES_SERVICE_OPTIONS = [
  {
    description: "",
    icon: RayClusterIcon,
    id: "1",
    name: "KubeRay",
    disabled: false
  },
  {
    description: "",
    icon: KubeflowIcon,
    id: "7",
    name: "KubeFlow",
    disabled: true
  }
];

const gerServices = (type: string) => {
  switch (type) {
    case ClusterType.KUBERNETES:
      return KUBERNETES_SERVICE_OPTIONS;
    case ClusterType.BARE_METAL:
    case ClusterType.BARE_METAL_REQUEST:
      return BARE_METAL_SERVICE_OPTIONS;
    default:
      return SERVICE_OPTIONS;
  }
};

export const bareMetalHardware = [
  "H100 80G PCIe",
  "H100 NVL",
  "H100 80GB HBM3",
  "H100 PCIe",
  "A100 80G PCIe NVLink",
  "A100 80GB PCIe",
  "A100-SXM4-80GB",
  "A100-SXM4-40GB",
  "RTX 6000 Ada Generation",
  "L40S",
  "L40",
  "A40-8Q",
  "A40 PCIe",
  "A40",
  "A100-PCIE-40GB",
  "M3 Ultra",
  "A30",
  "RTX 5000 Ada Generation",
  "RTX A6000",
  "M2 Ultra"
];

const KUBERNETES_CLUSTER_TYPES = [
  {
    id: "inference",
    name: "Inference",
    description: "Production ready clusters for low latency inference and heavy workloads",
    icon: InferenceIcon,
    validate: (values: Partial<ClusterForm>) => {
      if (values.nvLink && values.supplier === "2") {
        return false;
      }
      return true;
    }
  },
  {
    id: "train",
    name: "Train",
    description: "Production ready clusters for machine learning  models training and fine tuning",
    icon: SpannerIcon,
    validate: (values: Partial<ClusterForm>) => {
      if (values.supplier === "2") {
        return false;
      }
      return true;
    }
  }
];

const RAY_CLUSTERS_TYPES = [
  {
    id: "general",
    name: "General",
    description: "Best for prototyping or general E2E Workloads",
    icon: LightningIcon
  },
  {
    id: "train",
    name: "Train",
    description: "Production ready clusters for machine learning  models training and fine tuning",
    icon: SpannerIcon,
    validate: (values: Partial<ClusterForm>) => {
      if (values.supplier === "2") {
        return false;
      }
      return true;
    }
  },
  {
    id: "inference",
    name: "Inference",
    description: "Production ready clusters for low latency inference and heavy workloads",
    icon: InferenceIcon,
    validate: (values: Partial<ClusterForm>) => {
      if (values.nvLink && values.supplier === "2") {
        return false;
      }
      return true;
    }
  }
];

const normaliseCreateClusterData = (clusterType: string, result: CreateClusterDataResponse) => {
  const hardwareManufacturers: {
    value: string;
    label: string;
    icon?: typeof NvidiaIcon;
  }[] = [];
  const hardwareTypes: {
    value: string;
    label: string;
  }[] = [];
  const hardwareMap: ClusterHardwareMap = {};

  const hardwareList: ClusterHardware[] = [];

  const bareMetalHardwareList = bareMetalHardware.map((hardware) => hardware.toLowerCase());
  const hardwares =
    clusterType === ClusterType.BARE_METAL_REQUEST
      ? result.hardwares.filter(
          ({ type, name }) => type === "GPU" && bareMetalHardwareList.includes(name.toLowerCase())
        )
      : result.hardwares;

  for (const hardware of hardwares) {
    const hardwareType = hardware.type.toLowerCase();
    const hardwareManufacturer = hardware.brand_name.toLocaleUpperCase();
    const { hardwareManufacturerColor, hardwareManufacturerIcon } =
      getHardware(hardwareManufacturer) || {};
    const price = typeof hardware.price === "number" ? hardware.price : 0.9;

    if (hardwareTypes.filter(({ value }) => value === hardwareType).length === 0) {
      hardwareTypes.push({
        value: hardwareType,
        label: hardware.type
      });
    }

    if (hardwareManufacturers.filter(({ value }) => value === hardwareManufacturer).length === 0) {
      hardwareManufacturers.push({
        value: hardwareManufacturer,
        label: hardware.brand_name.toLocaleLowerCase() === "intel" ? "" : hardware.brand_name,
        icon: hardwareManufacturerIcon
      });
    }
    if (!hardwareMap[hardwareType]) {
      hardwareMap[hardwareType] = {};
    }
    if (!hardwareMap[hardwareType]![hardwareManufacturer]) {
      hardwareMap[hardwareType]![hardwareManufacturer] = [];
    }

    const newHardware = {
      id: `${hardware.hardware_id}`,
      available: hardware.hardware_quantity,
      hardwareManufacturer: hardware.brand_name,
      hardwareManufacturerColor,
      hardwareManufacturerIcon,
      hardwareName: hardware.name,
      busyPercent: busyPercentage(hardware.busy_percent),
      price,
      total: hardware.hardware_quantity,
      disabled: false,
      type: hardware.type
    };

    if (clusterType === "kubernetes") {
      newHardware.disabled = hardware.hardware_quantity === 0;
    }

    hardwareMap[hardwareType]![hardwareManufacturer]?.push(newHardware);
    hardwareList.push(newHardware);
  }

  const durations = result.durations.map((duration) => {
    return {
      duration: duration.duration.toLowerCase(),
      percentage: duration.percent * 100
    };
  });

  durations.sort((a, b) => {
    const c = DURATION_ORDERS.indexOf(a.duration.toLowerCase());
    const d = DURATION_ORDERS.indexOf(b.duration.toLowerCase());

    return d - c;
  });

  return {
    connectivityTiers: CONNECTIVITY_TIER_OPTIONS.map((tier) => {
      const foundTier = result.connectivity_tiers?.find(
        ({ connectivity_tier }) => tier.id === `${connectivity_tier}`
      );

      if (!foundTier) {
        return {
          ...tier,
          disabled: true,
          unavailable: true
        };
      }

      return {
        id: `${foundTier.connectivity_tier}`,
        downloadSpeedMbps: foundTier.download_speed_mbps,
        name: foundTier.name,
        uploadSpeedMbps: foundTier.upload_speed_mbps,
        disabled: false
      };
    }),
    durations,
    locations: (result.locations || []).map((location) => {
      return {
        busyPercentage: busyPercentage(location.busy_percent),
        description: location.description,
        id: `${location.id}`,
        name: location.name,
        code: location.iso2
      };
    }),
    services: gerServices(clusterType).map((service) => {
      const foundTier = result.services.find(({ id }) => service.id === `${id}`);

      if (!foundTier) {
        return {
          ...service,
          disabled: true,
          unavailable: true
        };
      }

      const { icon } = getService(service.name);

      return {
        description: service.description,
        icon,
        id: `${service.id}`,
        name: service.name,
        disabled: typeof service.disabled === "boolean" ? service.disabled : false
      };
    }),
    suppliers: (clusterType === "kubernetes" ? KUBERNETES_SUPPLIER_OPTIONS : SUPPLIER_OPTIONS).map(
      (supplier) => {
        const foundSupplier = result.suppliers?.find(({ id }) => supplier.id === `${id}`);

        if (!foundSupplier) {
          return {
            ...supplier,
            disabled: typeof supplier.disabled === "boolean" ? supplier.disabled : true,
            unavailable: true
          };
        }

        let icon: React.ReactNode = null;

        switch (foundSupplier.name.toLowerCase()) {
          case "io.net":
            icon = <IoIcon className="h-[14px] w-[14px]" />;
            break;
          case "render network":
            icon = <RenderNetworkIcon className="h-[18px]" />;
            break;
          case "filecoin":
            icon = <FileCoinIcon className="h-[13px]" />;
            break;
        }

        return {
          busyPercent: busyPercentage(foundSupplier.busy_percent),
          description: foundSupplier.description,
          icon,
          id: `${foundSupplier.id}`,
          name: foundSupplier.name,
          disabled: false,
          validate: supplier.validate
        };
      }
    ),
    clusterTypes:
      clusterType === ClusterType.KUBERNETES ? KUBERNETES_CLUSTER_TYPES : RAY_CLUSTERS_TYPES,
    hardwareTypes,
    hardwareManufacturers,
    hardwares: hardwareMap,
    hardwareList: hardwareList,
    sustainable: {
      validate: () => {
        return false;
      }
    },
    securityCompliances: SECURITY_COMPLIANCE_OPTIONS.map((option) => {
      const { id } = option;
      const disabled =
        (id === "SOC2/HIPAA" && result.securitySoc2Enabled === false) ||
        (id === "End-to-End Encrypted" && false);

      return {
        ...option,
        disabled,
        unavailable: true
      };
    })
  } as CreateClusterData;
};

const DURATION_ORDERS = ["weekly", "daily", "hourly"];

export const getClusterTotalCost = ({
  durationQuantity,
  durationType,
  durationPercentage,
  averagePerCard,
  hardwareQuantity,
  currencyConversionFeePrecent = 0.02,
  ionetFeePrecent = 0.0025
}: {
  durationQuantity?: number;
  durationType?: string;
  durationPercentage?: number;
  averagePerCard?: number;
  hardwareQuantity?: number;
  currencyConversionFeePrecent?: number;
  ionetFeePrecent?: number;
}) => {
  if (
    durationQuantity === undefined ||
    durationType === undefined ||
    averagePerCard === undefined ||
    durationPercentage === undefined ||
    currencyConversionFeePrecent === undefined ||
    ionetFeePrecent === undefined
  ) {
    return undefined;
  }

  const hourQuantity =
    durationQuantity !== undefined
      ? getClusterHourCount(durationQuantity, durationType)
      : undefined;
  const realAveragePerCard = averagePerCard * (durationPercentage / 100);
  const grossCost =
    hourQuantity !== undefined && hardwareQuantity !== undefined
      ? realAveragePerCard * hourQuantity * hardwareQuantity
      : undefined;

  if (grossCost === undefined) {
    return;
  }
  const totalCost = grossCost + grossCost * (currencyConversionFeePrecent + ionetFeePrecent);

  return totalCost;
};

export const getClusterResultsRoute = ({
  tool,
  clusterType
}: {
  tool: string;
  clusterType: string;
}) => {
  switch (clusterType) {
    case "enterprise":
      return `/${tool}/enterprise-clusters`;
    case "bare-metal":
      return `/${tool}/bare-metal`;
    case "mega":
      return `/${tool}/mega-clusters`;
    case "kubernetes":
      return `/${tool}/kubernetes`;
    default:
      return `/${tool}/clusters`;
  }
};

export const getClusterDetailsRoute = ({
  tool,
  clusterType,
  clusterId
}: {
  tool: string;
  clusterType: string;
  clusterId: string;
}) => {
  return `${getClusterResultsRoute({
    tool,
    clusterType
  })}/${clusterId}`;
};

export const getClusterPackageName = (values: ClusterForm) => {
  const { hardwareType, hardwareManufacturer } = values;

  if (hardwareType === "cpu" && hardwareManufacturer.toLowerCase() !== "apple") {
    return "ray";
  }
  if (hardwareType === "cpu" && hardwareManufacturer.toLowerCase() === "apple") {
    return "ray-arm";
  }
  if (hardwareType === "gpu") {
    return "ray-gpu";
  }
};

const normaliseClusterHourlyEarnings = (data: ClusterHourlyEarningsResponseType[]) => {
  return data.map((earning: ClusterHourlyEarningsResponseType) => {
    return {
      startDatetime: earning.start_datetime,
      endDatetime: earning.end_datetime,
      totalEarnings: earning.total_earnings,
      hourlyEarnings: earning.hourly_earnings
    };
  }) as ClusterHourlyEarningsType[];
};
