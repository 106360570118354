import { executeAPIRequest } from "./api";
import { fillWith } from "./array";

export const fetchLatestTerms = async () => {
  // const slaInfo = await fetchSla();
  const slaInfo = await fetchUserSla();
  const { slaPath } = slaInfo;
  const response = await fetch(slaPath);
  const data = await response.text();
  const { 1: contents } = data.split(TOC_SEPARATOR);

  const headings = findMarkdownHeadings(contents);
  const toc: string[] = [];
  let contentsWithAnchors = contents;
  let i = 1;

  for (const { level, text } of headings) {
    const prefix = fillWith(level)
      .map(() => "#")
      .join("");
    const anchor = `#anchor-${i}`;

    toc.push(`${prefix} [${text}](${anchor})`);

    contentsWithAnchors = contentsWithAnchors.split(text).join(`[](${anchor.slice(1)}) ${text}`);
    i++;
  }

  return {
    toc: toc.join("\n\n"),
    contents: contentsWithAnchors
  };
};

const TOC_SEPARATOR = "==========";

export const findMarkdownHeadings = (markdown: string) => {
  const headingRegex = /^(#{1,6})\s+(.+)$/gm; // Matches headings from # to ###### with their content
  const headings = [];
  let match;

  while ((match = headingRegex.exec(markdown)) !== null) {
    const level = match[1].length; // The number of `#` symbols indicates the heading level
    const text = match[2].trim(); // The heading text
    headings.push({ level, text });
  }

  return headings;
};

export const fetchSla = async () => {
  const response = await executeAPIRequest<{
    data: {
      sla_version: string;
      sla_path: string;
    };
  }>({
    method: "get",
    url: `/io/sla`
  });
  const { data } = response;

  return {
    slaVersion: data.sla_version,
    slaPath: data.sla_path
  };
};

export const fetchUserSla = async () => {
  const response = await executeAPIRequest<{
    data: {
      sla_id: number;
      sla_version: string;
      sla_path: string;
      is_signed: boolean;
    };
  }>({
    method: "get",
    url: `/io-user/sla`
  });
  const { data } = response;
  const { sla_id, sla_version, sla_path, is_signed } = data;

  return {
    slaId: sla_id,
    slaVersion: sla_version,
    slaPath: sla_path,
    isSigned: is_signed
    // isSigned: false
  };
};

export const signUserSla = async ({ slaId }: { slaId: number }) => {
  // const ipAddress = await getIpAddress();
  const response = await executeAPIRequest<{
    data: string;
  }>({
    method: "post",
    url: `/io-user/sla/sign`,
    options: {
      data: {
        sla_id: slaId,
        ip_address: null
        // ip_address: ipAddress
      }
    }
  });
  const { data } = response;

  return data;
};

// export const getIpAddress = async () => {
//   try {
//     const response = await fetch("https://api.ipify.org?format=json");
//     const data = await response.json();

//     return data.ip;
//   } catch (e) {
//     throw new Error(`unable to get user ip address: ${e}`);
//   }
// };
