import {
  ClusterForm,
  ClusterHardwareInfo,
  ClusterHardwareInfoMap,
  ClusterType,
  SimpleCluster,
  SimpleClusterDetail,
  SimpleClusterDetailResponse,
  SimpleClusterForm,
  SimpleClusterHardware,
  SimpleClusterHardwareInfoResponse,
  SimpleClusterHardwareResponse,
  SimpleClusterLocation,
  SimpleClusterResponse,
  SimpleClusterWorker,
  SimpleClusterWorkerInfo,
  SimpleClusterWorkerInfoResponse,
  SimpleClusterWorkerResponse
} from "@/types";
import { stringify } from "qs";
import { create } from "zustand";
import { executeAPIRequest } from "@/utils/api";
import { filterNull } from "@/utils";
import { useUserStore } from "@/store/user";
import { getHardware } from "@/utils/mapping";
import env from "@/env";
import { createContext } from "react";
import { STATUS_MAP } from "@/store/cluster";
import { DEFAULT_PAGINATION_LIMIT } from "@/constants";
import { InferenceIcon } from "@/components/Icons/InferenceIcon";
import { SpannerIcon } from "@/components/Icons/SpannerIcon";

type UseSimpleClusterStoreProps = {
  createCluster: (values: SimpleClusterForm) => Promise<string | undefined>;
  fetchClusterHardwareInfo: (
    values: Partial<SimpleClusterForm>
  ) => Promise<ClusterHardwareInfo | null>;
  fetchSimpleClusterRequest: (options: {
    status: string;
    page?: number;
    limit?: number;
  }) => Promise<{
    resultCount: number;
    results: SimpleCluster[];
    statuses: string[];
  }>;
  removeCluster: (id: string) => Promise<void>;
  fetchSimpleClusterDetail: (options: { id: string }) => Promise<SimpleClusterDetail>;
  fetchSimpleClusterWorkerInfo: (options: { id: string }) => Promise<SimpleClusterWorkerInfo>;
  fetchLocations: () => Promise<SimpleClusterLocation[]>;
  fetchHardware: (
    values: Partial<SimpleClusterForm>
  ) => Promise<{ total: number; hardware: SimpleClusterHardware[] }>;
};

const hardwareType = "gpu";
export const durations = [
  {
    duration: "hourly",
    percentage: 1
  },
  {
    duration: "daily",
    percentage: 0.9
  },
  {
    duration: "weekly",
    percentage: 0.8
  },
  {
    duration: "monthly",
    percentage: 0.7
  }
];

export const CONNECTIVITY_TIER_OPTIONS = [
  {
    id: "1",
    downloadSpeedMbps: 100,
    name: "Low Speed",
    uploadSpeedMbps: 10
  },
  {
    id: "2",
    downloadSpeedMbps: 400,
    name: "Medium Speed",
    uploadSpeedMbps: 300
  },
  {
    id: "3",
    downloadSpeedMbps: 800,
    name: "High Speed",
    uploadSpeedMbps: 600
  },
  {
    id: "4",
    downloadSpeedMbps: 1600,
    name: "Ultra High Speed",
    uploadSpeedMbps: 1200
  }
];

export const KUBERNETES_CLUSTER_TYPES = [
  {
    id: "inference",
    name: "Inference",
    disabled: true,
    description: "Production ready clusters for low latency inference and heavy workloads",
    icon: InferenceIcon,
    validate: (values: Partial<ClusterForm>) => {
      if (values.nvLink && values.supplier === "2") {
        return false;
      }
      return true;
    }
  },
  {
    id: "train",
    name: "Train",
    description: "Production ready clusters for machine learning  models training and fine tuning",
    icon: SpannerIcon,
    validate: (values: Partial<ClusterForm>) => {
      if (values.supplier === "2") {
        return false;
      }
      return true;
    }
  }
];

export const createClusterData = {
  clusterTypes: [],
  locations: [],
  services: [],
  suppliers: [],
  hardwareTypes: [],
  hardwareManufacturers: [],
  hardwares: {},
  securityCompliances: [],
  hardwareList: [],
  durations,
  connectivityTiers: CONNECTIVITY_TIER_OPTIONS
};

export const newInitialFormValues = {
  name: "",
  clusterType: "general",
  kubeRay: false,
  supplier: "1",
  securityCompliance: "",
  service: "",
  connectivityTier: "",
  hardwareManufacturer: "",
  hardwareType,
  durationQuantity: 1,
  durationType: "weekly",
  hardware: "",
  locations: [],
  sustainable: false,
  masterNode: "1",
  currency: "iocoin",
  createClusterData
} as SimpleClusterForm;

export const getClusterHourCount = (paymentQuantity: number, duration: string) => {
  switch (duration) {
    case "hourly":
      return paymentQuantity;
    case "daily":
      return 24 * paymentQuantity;
    case "weekly":
      return 7 * 24 * paymentQuantity;
    case "monthly":
      return 4 * 7 * 24 * paymentQuantity;
  }
};

export const createSimpleClusterStore = (clusterType: ClusterType) =>
  create<UseSimpleClusterStoreProps>(() => ({
    removeCluster: async (id: string) => {
      await executeAPIRequest<{
        data: SimpleClusterWorkerInfoResponse;
        status: string;
      }>({
        method: "delete",
        url: `/io-cloud/${clusterType}/cluster/${id}`
      });
    },
    fetchHardware: async (values: Partial<SimpleClusterForm>) => {
      const response = await executeAPIRequest<{
        data: {
          total: number;
          hardware: SimpleClusterHardwareResponse[];
        };
      }>({
        method: "get",
        url: `/io-cloud/${clusterType}/hardware?${stringify(
          filterNull({
            connectivity_tier: values.connectivityTier,
            ...(values.locations?.length
              ? {
                  location_ids: `[${values.locations?.join(",")}]`
                }
              : {})
          })
        )}`
      });

      return {
        total: response.data.total,
        hardware: response.data.hardware?.map((item) => normaliseSimpleClusterHardware(item))
      };
    },
    fetchLocations: async () => {
      const response = await executeAPIRequest<{
        data: {
          total: number;
          locations: SimpleClusterLocation[];
        };
      }>({
        method: "get",
        url: `/io-cloud/${clusterType}/locations`
      });
      return response.data.locations;
    },
    fetchSimpleClusterWorkerInfo: async ({ id }) => {
      const response = await executeAPIRequest<{
        data: SimpleClusterWorkerInfoResponse;
      }>({
        method: "get",
        url: `/io-cloud/${clusterType}/cluster/${id}/workers`
      });

      return normaliseSimpleClusterWorkerInfo(response.data, clusterType);
    },
    fetchSimpleClusterRequest: async (options) => {
      const { status = "all", page = 1, limit = DEFAULT_PAGINATION_LIMIT } = options;

      const response = await executeAPIRequest<{
        data: {
          clusters: SimpleClusterResponse[];
          total: number;
          statuses: string[];
        };
      }>({
        method: "get",
        url: `/io-cloud/${clusterType}/cluster?${stringify({
          ...(status != "all" ? { status } : {}),
          page: page,
          page_size: limit
        })}`
      });

      const { data } = response;

      const results = data.clusters.map((cluster) => {
        return { ...normaliseSimpleCluster(cluster) };
      });

      return {
        resultCount: data.total,
        results,
        statuses: data.statuses
      };
    },
    fetchSimpleClusterDetail: async ({ id }) => {
      const response = await executeAPIRequest<{
        data: SimpleClusterDetailResponse;
      }>({
        method: "get",
        url: `/io-cloud/${clusterType}/cluster/${id}`
      });

      return normaliseSimpleClusterDetail(response.data, clusterType);
    },

    fetchClusterHardwareInfo: async (values: Partial<SimpleClusterForm>) => {
      const hardware_qty = values.hardwareQuantity || values.hardwareInfo?.quantity;
      const response = await executeAPIRequest<{
        data: SimpleClusterHardwareInfoResponse;
      }>({
        method: "get",
        url: `/io-cloud/${clusterType}/price?${stringify(
          filterNull({
            ...(clusterType === ClusterType.BARE_METAL
              ? { hardware_ids: `[${values.hardware}]` }
              : { hardware_id: values.hardware }),
            // hardware_ids: `[${values.hardware}]`,
            connectivity_tier: values.connectivityTier,
            service_id: values.service,
            ...(values.durationType ? { duration_type: values.durationType } : {}),
            ...(values.durationQuantity ? { duration_qty: values.durationQuantity } : {}),
            ...(hardware_qty ? { hardware_qty } : {}),
            ...(values.currency ? { currency: values.currency } : {}),
            ...(values.locations?.length || 0 > 0
              ? {
                  location_ids: `[${values.locations?.join(",")}]`
                }
              : {})
          })
        )}`
      });

      return normaliseSimpleClusterHardwareInfo(response.data);
    },

    createCluster: async (values: SimpleClusterForm) => {
      const payload = {
        resource_private_name: values.name,
        hardware_qty: values.hardwareQuantity,
        hardware_id: [values.hardware],
        quantity: values.hardwareQuantity,
        location_id: values.locations,
        connectivity_tier: clusterType === ClusterType.BARE_METAL ? values.connectivityTier : 4,
        user_id: useUserStore.getState().userId,
        currency: values.currency,
        duration_qty: values.durationQuantity,
        duration_type: values.durationType,
        transaction: values.hardwareInfo?.transaction
      };

      const data =
        clusterType === ClusterType.KUBERNETES //TODO remove
          ? {
              ...payload,
              // service_id: "3"
              // supplier_id: "1",
              // security_soc2: false,
              hardware_id: values.hardware,
              connectivity_tier: "4",
              additional_services: {
                k8s_dashboard: values.additionalService?.includes("dashboard"),
                run_benchmark: values.additionalService?.includes("benchmark")
              }
            }
          : payload;

      const response = await executeAPIRequest<{
        cluster_id: string;
        status: string;
      }>({
        method: "post",
        url: `/io-cloud/${clusterType}/deploy`,
        options: {
          data,
          ...(values.hardwareInfo?.transaction
            ? {
                headers: {
                  transaction: values.hardwareInfo?.transaction
                }
              }
            : {}),
          timeout: env.API_DEPLOY_CLUSTER_TIMEOUT
        }
      });

      return response.cluster_id;
    }
  }));

const normaliseSimpleClusterHardwareInfo = (result: SimpleClusterHardwareInfoResponse) => {
  if (typeof result.qty !== "number") {
    return {
      averagePerCard: 0,
      ioAveragePerCard: 0,
      conversionRate: 1,
      quantity: 0,
      transaction: "",
      availableQuantity: [],
      currencyConversionFeePrecent: result.currency_conversion_fee_percent,
      ionetFeePrecent: result.ionet_fee_percent,
      cost: 0,
      currencyConversionFee: 0,
      ionetFee: 0,
      totalCost: 0
    };
  }

  const transformedArray: ClusterHardwareInfoMap[] = [];
  result.available_quantities.forEach((value) =>
    transformedArray.push({ label: String(value), value: String(value) })
  );

  return {
    averagePerCard: result.avg,
    ioAveragePerCard: result.avg_iocoin,
    quantity: result.qty,
    availableQuantity: transformedArray,
    currencyConversionFeePrecent: result.currency_conversion_fee_percent,
    conversionRate: result.conversion_rate,
    ionetFeePrecent: result.ionet_fee_percent,
    transaction: result.transaction,
    cost: result.gross_cost,
    currencyConversionFee: result.currency_conversion_fee,
    ionetFee: result.ionet_fee,
    totalCost: result.total_cost
  } as ClusterHardwareInfo;
};

const normaliseSimpleCluster = (result: SimpleClusterResponse) => {
  const hardware = getHardware(result.brand_name || "");
  const status = STATUS_MAP[`${result.status.toLowerCase()}` as keyof typeof STATUS_MAP];
  return {
    ...status,
    id: result.id,
    hardwareName: result.hardware_name,
    name: result.name,
    served: result.served,
    brandName: result.brand_name,
    remaining: result.remaining,
    hardwareQuantity: result.hardware_quantity,
    percentCompleted: result.completed_percent,
    ...hardware
  } as SimpleCluster;
};

const normaliseSimpleClusterDetail = (
  result: SimpleClusterDetailResponse,
  clusterType: ClusterType
) => {
  const status = STATUS_MAP[`${result.status.toLowerCase()}` as keyof typeof STATUS_MAP];
  const clusterDetail = {
    ...status,
    id: result.id,
    served: result.served,
    remaining: result.remaining,
    percentCompleted: result.completed_percent,
    startedAt: result.started_at,
    createdAt: result.created_at,
    finishesAt: result.finishes_at,
    finishedAt: result.finished_at,
    downloadSpeedMbps: result.download_speed_mbps,
    uploadSpeedMbps: result.upload_speed_mbps,
    amountPaid: result.amount_paid,
    name: result.name,
    hardwareQuantity: result.hardware_info?.length
      ? result.hardware_info?.[0].quantity
      : result.hardware_quantity,
    hardwareName: result.hardware_info?.length ? result.hardware_info?.[0].hardware_name : "",
    status: result.status,
    baseTierName: result.base_tier_name,
    locations: result.locations
      ? result.locations.map((location) => {
          return {
            value: `${location.id}`,
            label: location.name,
            code: location.iso2
          };
        })
      : [],
    hardware: result.hardware_info.map(({ hardware_name, brand_name, quantity }) => ({
      name: hardware_name,
      brandName: brand_name,
      hardwareQuantity: quantity
    })),
    currency: result.asset
  } as SimpleClusterDetail;

  if (clusterType === ClusterType.KUBERNETES) {
    return { ...clusterDetail, k8sFile: result.k8s_file ?? "" };
  }

  return clusterDetail;
};

export const SimpleClusterResultsDataTableContext = createContext<{
  setSelectedCluster: React.Dispatch<React.SetStateAction<SimpleCluster | undefined>>;
  setSelectedAction: React.Dispatch<React.SetStateAction<string | undefined>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}>(undefined as any);

const normaliseSimpleClusterHardware = (result: SimpleClusterHardwareResponse) => {
  const { brand_name } = result;
  const hardwareManufacturer = brand_name.toLocaleUpperCase();
  const { hardwareManufacturerColor = "", hardwareManufacturerIcon = "" } =
    getHardware(hardwareManufacturer) || {};

  return {
    hardwareManufacturerColor,
    hardwareManufacturerIcon,
    brandName: brand_name,
    ...result
  } as SimpleClusterHardware;
};

const normaliseSimpleClusterWorkerInfo = (
  result: SimpleClusterWorkerInfoResponse,
  clusterType: ClusterType
) => {
  return {
    total: result.total,
    items: result.workers.map((worker) => {
      return normaliseSimpleClusterWorker(worker, clusterType);
    })
  } as SimpleClusterWorkerInfo;
};

const normaliseSimpleClusterWorker = (
  result: SimpleClusterWorkerResponse,
  clusterType: ClusterType
) => {
  const hardware = getHardware(result.brand_name || "");

  const baseWorker = {
    id: String(result.id),
    hardwareName: result.hardware,
    brandName: result.brand_name,
    hardwareQuantity: result.hardware_quantity,
    status: result.status,
    ...hardware
  };

  if (clusterType === ClusterType.KUBERNETES) {
    return baseWorker;
  }

  return {
    sshConnect: result.ssh_connect,
    sshLink: result.ssh_link,
    login: result.login,
    password: result.password,
    ...baseWorker
  } as SimpleClusterWorker;
};

export const useBareMetalStore = createSimpleClusterStore(ClusterType.BARE_METAL);
export const useKubernetesStore = createSimpleClusterStore(ClusterType.KUBERNETES);
