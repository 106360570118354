import { useState } from "react";
import { Link, useLocation } from "wouter";
import { slugify } from "@/utils/string";
import { MenuItem } from "@/types";

type MainMenuProps = {
  items?: MenuItem[];
  className?: string;
  isActive?: boolean;
  onClick?: (value: string) => void;
};

// const getDefaultActive = (items: MenuItem[]) => items.length ? items[0].value : '';

export const HorizontalMenu: React.FC<MainMenuProps> = ({
  items = [],
  className = "",
  onClick
}) => {
  const getDefaultActive = () => (items.length ? items[0].value : "");
  const [location] = useLocation();
  const [activeItem, setActiveItem] = useState(getDefaultActive);

  const handleClick = (value: string) => {
    setActiveItem(value);
    onClick?.(value);
  };

  return (
    <div
      data-testid="MainMenu"
      className={`flex flex-row justify-start overflow-x-auto border-b-[1px] pl-[10px] dark:border-b-gray-dark-500 ${className}`}
    >
      {items.map((item) => {
        const { value, label, href, disabled } = item;
        const active = href ? location.slice(0, href?.length) === href : value === activeItem;

        const linkClassName = `group relative mx-1.5 mb-[-1px] box-border flex flex-row items-center gap-2 rounded-t-md border-b-[2px] border-white px-1.5
              py-2 hover:text-white hover:before:absolute hover:before:bottom-[1px] hover:before:left-[30%] hover:before:right-[30%] hover:before:top-[50%]
              hover:before:block hover:before:bg-gray-dark-800 hover:before:opacity-80 hover:before:blur-[12px] dark:mb-0 dark:border-transparent
              ${
                disabled
                  ? `pointer-events-none opacity-20`
                  : active
                    ? `from-gray-light-600 to-gray-light text-black before:absolute before:bottom-[1px] before:left-[30%] before:right-[30%] before:top-[50%]
                       before:block before:bg-gray-dark-800 before:opacity-85 before:blur-[12px] dark:border-b-white dark:${href ? "bg-gray-dark-600" : "bg-black"} dark:text-white`
                    : `cursor-pointer border-b-gray-light-500 bg-white text-gray hover:text-black dark:border-b-[3px] dark:${href ? "bg-gray-dark-600" : "bg-black"} dark:hover:text-white`
              }`;

        return href ? (
          <Link
            href={href}
            key={value}
            data-testid={`MainMenu_${slugify(value)}`}
            className={linkClassName}
          >
            <span className="relative z-[1] whitespace-nowrap text-sm">{label}</span>
            {disabled && <span className="whitespace-nowrap text-sm">[soon]</span>}
          </Link>
        ) : (
          <div key={value} onClick={() => handleClick(value)} className={linkClassName}>
            <span className="relative z-[1] whitespace-nowrap text-sm">{label}</span>
          </div>
        );
      })}
    </div>
  );
};
