import { Fragment, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { ArrowDownIcon } from "@/components/Icons/ArrowDownIcon";
import { DeployClusterIcon } from "@/components/Icons/DeployClusterIcon";
import { SoonLabel } from "@/components/Common/SoonLabel/SoonLabel";
import { AuthMachineContext } from "@/store/machine/authMachine/authMachine";
import { AuthEventType } from "@/types/auth";
import { ClusterType } from "@/types";
import { CLUSTER_ITEMS } from "@/constants/cluster";
import { useLocation } from "wouter";
import { trackEvent } from "@/utils/analytics";
import { AnalyticsEventType } from "@/constants/analytics";

type CreateClusterButtonProps = {
  className?: string;
};

export const CreateClusterButton: React.FC<CreateClusterButtonProps> = ({ className = "" }) => {
  const authActorRef = AuthMachineContext.useActorRef();
  const [location] = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger
        className={`subtext-small flex h-8 flex-row items-center rounded border border-gray-dark-900 px-4 transition-colors hover:border-gray-dark-1200 hover:bg-gray-dark-1100 ${className}`}
      >
        <DeployClusterIcon className={"w-[18px]"} />
        <span className="mx-2 flex-1">Deploy Cluster</span>
        <ArrowDownIcon className="h-[6px] text-gray" />
      </PopoverTrigger>

      <PopoverContent
        data-testid={"CreateClusterModal_wrapper"}
        className="z-[31] mt-2 w-[270px] border-none p-0"
        align="center"
      >
        <div className="flex w-[270px] flex-auto flex-col overflow-hidden rounded-[16px] bg-white dark:border dark:border-gray-dark-900 dark:bg-black">
          <div className="flex flex-col gap-1 px-2 py-4">
            {CLUSTER_ITEMS.map((category) => {
              const { value, title, disabled } = category;
              const isSameLocation = location === category.url;

              return (
                <Fragment key={value}>
                  <a
                    data-testid={`CreateClusterModal_${value}`}
                    aria-disabled={disabled}
                    onClick={() => {
                      if (disabled || isSameLocation) {
                        return;
                      }
                      authActorRef.send({
                        type: AuthEventType.DEPLOY_CLUSTER,
                        value: value as ClusterType
                      });

                      trackEvent(AnalyticsEventType.CREATE_CLUSTER_START, {
                        clusterType: value as ClusterType
                      });

                      setIsOpen(false);
                    }}
                    className={`group flex flex-row items-center gap-2 px-6 py-2 transition-colors ${
                      disabled || isSameLocation
                        ? `opacity-40`
                        : `cursor-pointer hover:rounded-lg hover:bg-gray-light-800 dark:hover:bg-gray-dark-500`
                    }`}
                  >
                    <div className={"flex w-[27px] items-center justify-center"}>
                      <category.icon className="h-[18px] text-gray" />
                    </div>
                    <div className="label-medium flex w-full items-center text-black dark:text-white">
                      {title}
                      <div className={"ml-auto uppercase"}>{disabled ? <SoonLabel /> : null}</div>
                    </div>
                  </a>
                </Fragment>
              );
            })}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
