import BaseFieldHOC from "./BaseFieldHOC";

import RadioCardInput, { RadioCardInputProps } from "./inputs/RadioCardInput";
import TextInput, { TextInputProps } from "./inputs/TextInput";
import ToggleRowInput, { ToggleRowInputProps } from "./inputs/ToggleRowInput";
import CheckboxCardInput, { CheckboxCardInputProps } from "./inputs/CheckboxCardInput";
import SelectInput, { SelectInputProps } from "./inputs/SelectInput";
import RadioInput, { RadioInputProps } from "./inputs/RadioInput";
import TextArea from "@/components/forms/inputs/TextArea";

const RadioCardField = BaseFieldHOC<RadioCardInputProps>(RadioCardInput);
const TextField = BaseFieldHOC<TextInputProps>(TextInput);
const TextAreaField = BaseFieldHOC<TextInputProps>(TextArea);
const ToggleRowField = BaseFieldHOC<ToggleRowInputProps>(ToggleRowInput);
const CheckboxCardField = BaseFieldHOC<CheckboxCardInputProps>(CheckboxCardInput);
const SelectInputField = BaseFieldHOC<SelectInputProps>(SelectInput);
const RadioInputField = BaseFieldHOC<RadioInputProps>(RadioInput);

export {
  RadioCardField,
  TextField,
  TextAreaField,
  ToggleRowField,
  CheckboxCardField,
  SelectInputField,
  RadioInputField
};
