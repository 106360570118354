export type TextAreaProps = {
  value?: string;
  disabled?: boolean;
  name: string;
  rows?: number;
  placeholder?: string;
  onChange: (value: string) => void;
};

export default function TextArea({
  onChange,
  value = "",
  name,
  placeholder,
  rows = 6
}: TextAreaProps) {
  return (
    <textarea
      value={value}
      name={name}
      rows={rows}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      className="resize-none rounded-md border border-gray-dark-900 bg-white px-4 py-2 text-xs text-black focus:border-black dark:bg-black dark:text-white dark:focus:border-white"
    />
  );
}
