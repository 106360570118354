import { IconProps } from ".";

export const AlertCircleRedIcon: React.FC<IconProps> = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.99996 0.666687C13.6025 0.666687 17.3333 4.39605 17.3333 8.99673C17.335 11.1838 16.4762 13.2837 14.9424 14.8433C13.4085 16.4028 11.3226 17.2968 9.13498 17.3323C6.94737 17.3677 4.83357 16.5417 3.24996 15.0327C1.66634 13.5236 0.73995 11.4526 0.670793 9.26662L0.666626 8.99673L0.669959 8.76348C0.793293 4.27109 4.47496 0.666687 8.99996 0.666687ZM9.00829 11.4957L8.90246 11.5016C8.69992 11.5257 8.51325 11.6232 8.3778 11.7756C8.24236 11.928 8.16755 12.1249 8.16755 12.3287C8.16755 12.5326 8.24236 12.7294 8.3778 12.8819C8.51325 13.0343 8.69992 13.1318 8.90246 13.1559L8.99996 13.1617L9.10579 13.1559C9.30833 13.1318 9.49501 13.0343 9.63045 12.8819C9.76589 12.7294 9.8407 12.5326 9.8407 12.3287C9.8407 12.1249 9.76589 11.928 9.63045 11.7756C9.49501 11.6232 9.30833 11.5257 9.10579 11.5016L9.00829 11.4957ZM8.99996 4.83171C8.79585 4.83173 8.59884 4.90664 8.44632 5.04222C8.29379 5.1778 8.19634 5.36462 8.17246 5.56725L8.16663 5.66471V8.99673L8.17246 9.09419C8.19655 9.29665 8.29409 9.48325 8.4466 9.61864C8.5991 9.75402 8.79599 9.8288 8.99996 9.8288C9.20393 9.8288 9.40081 9.75402 9.55332 9.61864C9.70583 9.48325 9.80337 9.29665 9.82746 9.09419L9.83329 8.99673V5.66471L9.82746 5.56725C9.80358 5.36462 9.70613 5.1778 9.5536 5.04222C9.40107 4.90664 9.20407 4.83173 8.99996 4.83171Z"
      fill="#EF4444"
      style={{ fill: "color(display-p3 0.9373 0.2667 0.2667)", fillOpacity: 1 }}
    />
  </svg>
);
