import { IconProps } from ".";

export const WarnTriangleIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width={18}
      height={16}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.68556 0.896086L17.2272 13.959C17.2967 14.0794 17.3333 14.2159 17.3333 14.3549C17.3333 14.4938 17.2967 14.6303 17.2272 14.7507C17.1577 14.871 17.0578 14.971 16.9375 15.0405C16.8171 15.11 16.6806 15.1465 16.5416 15.1465H1.4583C1.31933 15.1465 1.18281 15.11 1.06246 15.0405C0.942109 14.971 0.842171 14.871 0.772688 14.7507C0.703204 14.6303 0.666625 14.4938 0.666626 14.3549C0.666627 14.2159 0.703208 14.0794 0.772693 13.959L8.31435 0.896086C8.38384 0.775743 8.48378 0.67581 8.60413 0.606331C8.72448 0.536852 8.86099 0.500275 8.99996 0.500275C9.13892 0.500275 9.27544 0.536852 9.39579 0.606331C9.51614 0.67581 9.61608 0.775743 9.68556 0.896086ZM8.99996 11.1881C8.56272 11.1881 8.20827 11.5425 8.20827 11.9798V11.9798C8.20827 12.417 8.56272 12.7715 8.99996 12.7715V12.7715C9.4372 12.7715 9.79165 12.417 9.79165 11.9798V11.9798C9.79165 11.5425 9.4372 11.1881 8.99996 11.1881V11.1881ZM8.99996 5.64624C8.56272 5.64624 8.20827 6.00069 8.20827 6.43793V8.81301C8.20827 9.25025 8.56272 9.6047 8.99996 9.6047V9.6047C9.4372 9.6047 9.79165 9.25025 9.79165 8.81301V6.43793C9.79165 6.00069 9.4372 5.64624 8.99996 5.64624V5.64624Z"
        fill="#E6770A"
        style={{ fill: "color(display-p3 0.9020 0.4667 0.0392)", fillOpacity: 1 }}
      />
    </svg>
  );
};
