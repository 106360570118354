import { IconProps } from ".";

export const GpuIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.82634 2.16669L11.173 2.16669C11.5141 2.16687 11.8486 2.26219 12.1385 2.44194C12.4281 2.62155 12.6619 2.87832 12.8137 3.18344C12.8139 3.18369 12.814 3.18394 12.8141 3.18419L15.1134 7.77615C15.1482 7.84565 15.1663 7.9223 15.1663 8.00002V12C15.1663 12.4863 14.9732 12.9526 14.6294 13.2964C14.2856 13.6402 13.8192 13.8334 13.333 13.8334H2.66634C2.18011 13.8334 1.7138 13.6402 1.36998 13.2964C1.02616 12.9526 0.833008 12.4863 0.833008 12V8.00002C0.833008 7.9223 0.851126 7.84565 0.885924 7.77615L3.18524 3.18419C3.18537 3.18394 3.18549 3.18369 3.18561 3.18345C3.3374 2.87833 3.57123 2.62155 3.86087 2.44194C4.15073 2.26219 4.48526 2.16687 4.82634 2.16669ZM4.82634 3.16669C4.6714 3.16682 4.51956 3.21015 4.38788 3.2918C4.25612 3.37351 4.14976 3.49035 4.08077 3.62919L4.08009 3.63055L2.14255 7.50002H13.8568L11.9193 3.63055L11.9186 3.62919C11.8496 3.49035 11.7432 3.37351 11.6115 3.2918C11.4798 3.21015 11.328 3.16682 11.173 3.16669M14.1663 8.50002H1.83301V12C1.83301 12.221 1.92081 12.433 2.07709 12.5893C2.23337 12.7456 2.44533 12.8334 2.66634 12.8334H13.333C13.554 12.8334 13.766 12.7456 13.9223 12.5893C14.0785 12.433 14.1663 12.221 14.1663 12V8.50002ZM11.173 3.16669H4.82634H11.173ZM3.49968 10.6667C3.49968 10.3905 3.72353 10.1667 3.99968 10.1667H4.00634C4.28248 10.1667 4.50634 10.3905 4.50634 10.6667C4.50634 10.9428 4.28248 11.1667 4.00634 11.1667H3.99968C3.72353 11.1667 3.49968 10.9428 3.49968 10.6667ZM6.16634 10.6667C6.16634 10.3905 6.3902 10.1667 6.66634 10.1667H6.67301C6.94915 10.1667 7.17301 10.3905 7.17301 10.6667C7.17301 10.9428 6.94915 11.1667 6.67301 11.1667H6.66634C6.3902 11.1667 6.16634 10.9428 6.16634 10.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};
