import { PageWrapper } from "@/components/Common/PageWrapper/PageWrapper";
import { IoIcon } from "@/components/Icons/IoIcon";
import env from "@/env";
import parse from "html-react-parser";
import { useMemo } from "react";

type MaintenancePageProps = {
  title?: string;
  description?: string;
};

export const MaintenancePage = ({ title, description }: MaintenancePageProps) => {
  const content = useMemo(() => {
    return parse(description || env.MAINTENANCE_DESCRIPTION);
  }, [description]);

  return (
    <PageWrapper>
      <div className="flex flex-1 flex-col items-center justify-center lg:mx-10">
        <img src="/assets/images/maintenance-background.png" className="w-[506px] max-lg:hidden" />
        <img
          src="/assets/images/maintenance-background-mobile.png"
          className="w-full max-w-[375px] lg:hidden"
        />

        <div className="relative mt-[-115px] flex flex-col items-center gap-5">
          <div className="flex flex-col items-center gap-2 max-lg:px-3">
            <div className="lg:title-h4 title-h5 text-center text-black dark:text-white">
              {title || env.MAINTENANCE_TITLE}
            </div>
            <p className="subtext-small text-center text-gray">{content}</p>
          </div>
          <IoIcon className="ml-[3px] h-[18px]" />
        </div>
      </div>
    </PageWrapper>
  );
};

export default MaintenancePage;
