import React from "react";
import { Link, LinkProps } from "wouter";

export const SmartLink: React.FC<LinkProps & { className?: string }> = ({
  href,
  children,
  className = "",
  ...rest
}) => {
  // const {className = ''} = rest ?? '';
  if (typeof href === "string" && href.indexOf("://") > -1) {
    return (
      <a href={href} target="_blank" className={className}>
        {children}
      </a>
    );
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Link href={href} {...(rest as any)} className={className}>
      {children}
    </Link>
  );
};
