import { IconProps } from ".";

export const InfoCircleIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99998 0.333313C3.31808 0.333313 0.333313 3.31808 0.333313 6.99998C0.333313 10.6819 3.31808 13.6666 6.99998 13.6666C10.6819 13.6666 13.6666 10.6819 13.6666 6.99998C13.6666 3.31808 10.6819 0.333313 6.99998 0.333313ZM5.66665 6.33331C5.66665 5.96512 5.96512 5.66665 6.33331 5.66665H6.99998C7.36817 5.66665 7.66665 5.96512 7.66665 6.33331V9.66665C7.66665 10.0348 7.36817 10.3333 6.99998 10.3333C6.63179 10.3333 6.33331 10.0348 6.33331 9.66665V6.99998C5.96512 6.99998 5.66665 6.7015 5.66665 6.33331ZM6.33331 4.33331C6.33331 4.7015 6.63179 4.99998 6.99998 4.99998C7.36817 4.99998 7.66665 4.7015 7.66665 4.33331C7.66665 3.96512 7.36817 3.66665 6.99998 3.66665C6.63179 3.66665 6.33331 3.96512 6.33331 4.33331Z"
        fill="#0070F3"
        style={{ fill: "color(display-p3 0.0000 0.4392 0.9529)", fillOpacity: 1 }}
      />
    </svg>
  );
};
