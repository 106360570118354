import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import {
  UserNotification,
  UserNotificationResponse,
  UserPayout,
  UserPayoutResponse,
  UserTransaction,
  UserTransactionResponse
} from "@/types";
import { executeAPIRequest } from "@/utils/api";
import { getNotification } from "@/utils/mapping";
import { stringify } from "qs";
import { jsonify } from "@/utils";
// import { PublicKey } from "@solana/web3.js";
import { toFriendlyError } from "@/utils/api";
import { BalanceType, NotificationType, UserInfo } from "@/types/user";
import localCookies from "@/utils/cookie";
import { toStringWithLoginVersionSuffix } from "@/utils/user";
import env from "@/env";
import { toNumber } from "@/utils/number";

type UseUserStoreProps = {
  userId: string;
  theme: string;
  supplier?: string;
  hasSupplier?: boolean;
  balance?: number;
  balances?: { [k in BalanceType]: number };
  isLoadingBalances?: boolean;
  fetchNotificationCount: () => Promise<number>;
  fetchNotificationsRequest: (options: {
    type: NotificationType;
    page: number;
    limit: number;
  }) => Promise<{
    resultCount: number;
    results: UserNotification[];
  }>;
  removeNotification: (notificationId: string) => Promise<void>;
  removeAllNotifications: () => Promise<void>;
  fetchBalance: () => Promise<number>;
  fetchBalances: (refresh?: boolean) => Promise<{ [k in BalanceType]: number }>;
  initialisePayout: ({
    wallet,
    amount,
    recaptchaToken
  }: {
    wallet: string;
    amount: number;
    recaptchaToken: string;
  }) => Promise<UserPayout>;
  fetchWalletAddresses: () => Promise<{
    spl_address?: string;
    aptos_address?: string;
  }>;
  fetchSolanaAddress: () => Promise<string | null>;
  updateSolanaAddress: (address: string) => Promise<void>;
  updateAptosAddress: (address: string) => Promise<void>;
  generatePaymentLink: (options: {
    name: string;
    amount: number;
    type?: string;
    currency?: "usdc" | "iocoin";
  }) => Promise<string>;
  generateStripePaymentLink: (options: {
    name: string;
    amount: number;
    type?: string;
  }) => Promise<string>;
  assignSupplier: (supplierId: string) => Promise<void>;
  updateTheme: (mode: string) => void;
  fetchTransactionsRequest: (options: {
    page: number;
    limit: number;
    sorted?: string;
    type?: string;
    platform?: string;
    deviceName?: string;
    date?: { startDate?: string; endDate?: string };
  }) => Promise<{
    resultCount: number;
    results: UserTransaction[];
  }>;
  fetchTransactionDetail: (id: string) => Promise<UserTransaction>;
  fetchTransactions: () => Promise<UserTransaction[]>;
  setUserId: (userId?: string) => void;
  fetchPoints: () => Promise<{ total: number; gpu: number; cpu: number }>;
  userInfo?: UserInfo;
  fetchUserInfo: () => Promise<UserInfo>;
};

export const useUserStore = create(
  persist<UseUserStoreProps>(
    (set, get) => ({
      userId: "",
      theme: "dark",
      setUserId: (userId) => {
        set({ userId });
      },
      hasSupplier: false,
      updateTheme: (mode) => {
        set({
          theme: mode
        });
        document.getElementsByTagName("html")[0].setAttribute("data-theme", mode);
      },
      initialisePayout: async ({ wallet, amount, recaptchaToken }) => {
        try {
          const response = await executeAPIRequest<{
            data: UserPayoutResponse;
            status: string;
          }>({
            method: "post",
            url: `/payout?${stringify({ solana_address: wallet, usdc_amount: amount })}`,
            options: {
              headers: {
                recaptchaToken
              }
            }
          });

          return normaliseUserPayout(response.data);
        } catch (e) {
          throw e;
        }
      },
      fetchBalance: async () => {
        const { userId } = get();

        const response = await executeAPIRequest<{
          data: number;
          status: string;
        }>({
          method: "get",
          url: `/io-cloud/users/${userId}/balance`,
          options: {
            errorPrefix: "unable to retrieve balance"
          }
        });

        set({ balance: response.data });

        return response.data;
      },
      fetchBalances: async (refresh) => {
        const { balances, userId, isLoadingBalances } = get();

        const newBalances: { [k in BalanceType]: number } = {
          [BalanceType.CLOUD]: 0,
          [BalanceType.IOCLOUD]: 0,
          [BalanceType.WORKER]: 0,
          [BalanceType.BC8]: 0,
          [BalanceType.IOWORKER]: 0
        };

        if (isLoadingBalances || (!refresh && balances)) {
          return balances || newBalances;
        }

        set({ isLoadingBalances: true });

        try {
          const cloudResponse = await executeAPIRequest<{
            cloud_balance: number;
            cloud_iocoin_balance: number;
            worker_balance: number;
            worker_io_coin_balance: number;
            status: string;
          }>({
            method: "get",
            url: `/io-cloud/users/${userId}/balances`,
            options: {
              errorPrefix: "unable to retrieve balance"
            }
          });

          newBalances[BalanceType.CLOUD] = cloudResponse.cloud_balance;
          newBalances[BalanceType.IOCLOUD] = cloudResponse.cloud_iocoin_balance;
          newBalances[BalanceType.WORKER] = cloudResponse.worker_balance;
          newBalances[BalanceType.IOWORKER] = cloudResponse.worker_io_coin_balance;
        } catch (e) {
          console.log(e);
        }

        set({ balances: jsonify(newBalances), isLoadingBalances: false });

        return newBalances;
      },
      fetchNotificationCount: async () => {
        const { fetchNotificationsRequest } = get();

        const results = await Promise.allSettled(
          [NotificationType.SUCCESS, NotificationType.WARNING, NotificationType.ERROR].map(
            (type) => {
              return fetchNotificationsRequest({ type, page: 1, limit: 1 });
            }
          )
        );

        let total = 0;

        for (const result of results) {
          if (result.status !== "fulfilled") {
            continue;
          }

          total += result.value.resultCount;
        }

        return total;
      },
      fetchNotificationsRequest: async ({ type, page, limit }) => {
        const response = await executeAPIRequest<{
          status: string;
          data: {
            page: number;
            page_size: number;
            notifications: UserNotificationResponse[];
            total_pages: number;
          };
        }>({
          method: "get",
          url: `/io-worker/users/notifications?${stringify({
            type,
            page,
            page_size: limit
          })}`
        });

        const { data } = response;

        return {
          resultCount: data.total_pages * limit,
          results: data.notifications.map((result) => {
            return normaliseUserNotification(result);
          })
        };
      },
      removeNotification: async (notificationId) => {
        const { userId } = get();
        await executeAPIRequest({
          method: "delete",
          url: `/io-worker/users/${userId}/notifications?${stringify({
            notification_id: notificationId
          })}`
        });
      },
      removeAllNotifications: async () => {
        const { userId } = get();

        await executeAPIRequest({
          method: "delete",
          url: `/io-worker/users/${userId}/notifications?${stringify({ delete_all: true })}`
        });
      },
      fetchWalletAddresses: async () => {
        const { userId } = get();

        try {
          const response = await executeAPIRequest<{
            status: string;
            data: {
              spl_address: string | null;
              aptos_address: string | null;
            };
          }>({
            method: "get",
            url: `/io-worker/users/${userId}/wallet_addresses`
          });

          const { spl_address, aptos_address } = response.data;

          return {
            spl_address: typeof spl_address === "string" ? spl_address : undefined,
            aptos_address: typeof aptos_address === "string" ? aptos_address : undefined
          };
        } catch (e) {
          throw toFriendlyError(e);
        }
      },
      fetchSolanaAddress: async () => {
        const { userId } = get();

        try {
          const response = await executeAPIRequest<{
            data: string | null;
            status: string;
          }>({
            method: "get",
            url: `/io-worker/users/${userId}/solana_address`
          });

          return response.data;
        } catch (e) {
          if (e instanceof Error) {
            if (e.message === "Solana address not found") {
              return null;
            }

            throw e;
          }
        }

        return null;
      },
      updateSolanaAddress: async (address: string) => {
        const { userId } = get();

        // const publicKey = new PublicKey(address);
        // const isValidAddress = await PublicKey.isOnCurve(publicKey);

        // if (!isValidAddress) {
        //   throw new Error("Solana address is not valid");
        // }

        try {
          await executeAPIRequest<{
            data: boolean;
            status: string;
          }>({
            method: "put",
            url: `/io-worker/users/${userId}/update_solana_address?${stringify({
              ...(address.length > 0
                ? {
                    solana_address: address
                  }
                : {})
            })}`
          });
        } catch (e) {
          throw toFriendlyError(e);
        }

        // if (response.data === false) {
        //   throw new Error("Solana address is not valid");
        // }
      },
      updateAptosAddress: async (address: string) => {
        const { userId } = get();

        try {
          await executeAPIRequest<{
            data: boolean;
            status: string;
          }>({
            method: "put",
            url: `/io-worker/users/${userId}/update_aptos_address?${stringify({
              ...(address.length > 0
                ? {
                    aptos_address: address
                  }
                : {})
            })}`
          });
        } catch (e) {
          throw toFriendlyError(e);
        }
      },
      generatePaymentLink: async ({ name, amount, type, currency = "usdc" }) => {
        try {
          const { userId } = get();

          const response = await executeAPIRequest<{
            data: string;
            status: string;
          }>({
            method: "post",
            url: `/io-cloud/clusters/generate-payment-link?${stringify({
              cluster_name: name,
              currency,
              ...(type
                ? {
                    cluster_type: type === "kubernetes" ? "k8s_cluster" : "cluster"
                  }
                : {}),
              amount: Math.abs(amount),
              user_id: userId
            })}`,
            options: {
              errorPrefix: "unable to generate payment link"
            }
          });

          return response.data;
        } catch (e) {
          throw e;
        }
      },
      generateStripePaymentLink: async ({
        name,
        amount,
        type
      }: {
        name: string;
        amount: number;
        type?: string;
      }) => {
        try {
          const { userId } = get();

          const response = await executeAPIRequest<{
            data: string;
            status: string;
          }>({
            method: "post",
            url: `/io-cloud/clusters/stripe/generate-payment-link?${stringify({
              cluster_name: name,
              ...(type
                ? {
                    cluster_type: type === "kubernetes" ? "k8s_cluster" : "cluster"
                  }
                : {}),
              amount: amount,
              user_id: userId
            })}`,
            options: {
              errorPrefix: "unable to generate payment link"
            }
          });

          return response.data;
        } catch (e) {
          throw e;
        }
      },
      assignSupplier: async (supplierId: string) => {
        const { userId } = get();

        await executeAPIRequest<{
          data: string;
          status: string;
        }>({
          method: "put",
          url: `/io-cloud/users/${userId}/assign-user-supplier?${stringify({
            supplier_id: supplierId
          })}`
        });
      },
      fetchTransactionsRequest: async ({
        page,
        limit,
        sorted,
        type,
        platform,
        date,
        deviceName
      }) => {
        try {
          const response = await executeAPIRequest<{
            status: string;
            page_no: number;
            page_size: number;
            data: UserTransactionResponse[];
            total_results: number;
          }>({
            method: "get",
            url: `/transactions?${stringify({
              ...{
                page,
                page_size: limit,
                sorted
              },
              ...(date?.startDate && date?.endDate
                ? { start_date: date.startDate + "T00:00:00", end_date: date.endDate + "T23:59:59" }
                : {}),
              ...(type ? { type } : {}),
              ...(deviceName ? { device_name: deviceName } : {}),
              ...(platform ? { platform } : {})
            })}`
          });
          return {
            resultCount: response.total_results,
            results: response.data.map((transaction) => {
              return normaliseUserTransaction(transaction);
            })
          };
        } catch (e) {
          throw toFriendlyError(e);
        }
      },
      fetchTransactionDetail: async (id) => {
        try {
          const response = await executeAPIRequest<{
            status: string;
            data: UserTransactionResponse;
          }>({
            method: "get",
            url: `/transactions/${id}`
          });
          return normaliseUserTransaction(response.data);
        } catch (e) {
          throw toFriendlyError(e);
        }
      },
      fetchTransactions: async () => {
        const { userId } = get();

        const response = await executeAPIRequest<{
          data: UserTransactionResponse[];
          status: string;
        }>({
          method: "get",
          url: `/io-worker/users/${userId}/transaction`
        });

        return response.data.map((transaction) => {
          return normaliseUserTransaction(transaction);
        });
      },
      fetchPoints: async () => {
        const { userId } = get();

        const response = await executeAPIRequest<{
          data: number;
          status: string;
          gpu_points: number;
          cpu_points: number;
          gpu_points_season2: number;
          cpu_points_season2: number;
          gpu_points_season3: number;
          cpu_points_season3: number;
        }>({
          method: "get",
          url: `/io-cloud/users/${userId}/points`
        });

        return {
          total:
            response.data +
            (env.FEATUREFLAG_POINTS_SEASONS_ENABLED
              ? toNumber(response.gpu_points_season2) +
                toNumber(response.cpu_points_season2) +
                toNumber(response.gpu_points_season3) +
                toNumber(response.cpu_points_season3)
              : 0),
          gpu: response.gpu_points,
          cpu: response.cpu_points,
          gpu_points_season1: response.gpu_points,
          cpu_points_season1: response.cpu_points,
          ...response
        };
      },
      fetchUserInfo: async () => {
        try {
          const response = await executeAPIRequest<{
            data: {
              block_rewards_suspicious?: boolean;
              is_worker?: boolean;
              is_cloud?: boolean;
              inventory_access?: boolean;
            };
          }>({
            method: "get",
            url: `/me`
          });

          const { data } = response;
          const userInfo = {
            blockRewardsSuspicious: data.block_rewards_suspicious === true,
            isWorker: data.is_worker === true,
            isCloud: data.is_cloud === true,
            inventoryAccess: data.inventory_access === true
          } as UserInfo;

          set({ userInfo });

          return userInfo;
        } catch (e) {
          throw e;
        }
      }
    }),
    {
      name: toStringWithLoginVersionSuffix("ionet_authentication"),
      storage: createJSONStorage(() => localCookies),
      partialize: (state) => {
        return {
          ...state,
          balance: undefined,
          balances: undefined,
          isLoadingBalances: undefined,
          isLoggedIn: false,
          userId: ""
        };
      },
      onRehydrateStorage: () => {
        return (state, error) => {
          if (error) {
            console.log("an error happened during hydration", error);
          } else if (state) {
            //
          }
        };
      }
    }
  )
);

const normaliseUserNotification = (result: UserNotificationResponse) => {
  const notification = getNotification(result.type);

  return {
    id: result.id,
    text: result.text,
    createdAt: result.created_at,
    isRead: result.seen,
    isAdminNotification: result.is_admin_notification,
    ...notification
  } as UserNotification;
};

const normaliseUserPayout = (result: UserPayoutResponse) => {
  return {
    solscanUrl: result.solscan_url
  } as UserPayout;
};

export const normaliseUserTransaction = (result: UserTransactionResponse) => {
  return {
    ...result,
    txidUrl: result.txid_url,
    createdAt: result.created_at,
    sourceType: result.source_type,
    sourceId: result.source_id,
    originalAmount: result.original_amount,
    currencyConversionFeePrecent: result.currency_conversion_fee_precent,
    ionetFeePrecent: result.ionet_fee_precent,
    workerName: result.device_name,
    timeAmount: result.compute_minutes_served || 0 * 60,
    jobId: result.job_id,
    deviceId: result.device_id
  } as UserTransaction;
};
