import { CpuIcon } from "@/components/Icons/CpuIcon";
// import { E2EEncryptedIcon } from "@/components/Icons/E2EEncryptedIcon";
import { GpuIcon } from "@/components/Icons/GpuIcon";
// import { Soc2HipAaIcon } from "@/components/Icons/Soc2HipAaIcon";
import { executeAPIRequest } from "@/utils/api";
import {
  Brand,
  BrandResponse,
  LocationCity,
  LocationCityResponse,
  FilterGroup,
  Filters,
  InferenceDetail,
  InferenceDetailResponse,
  InferenceStats,
  LiveHardwarePricing,
  LiveHardwarePricingResponse,
  LocationDeviceResponse,
  LocationDevice,
  LocationCityGeoJSON,
  CountryHardwaresType,
  CountryHardwaresResponseType,
  DeviceInventoryResponseType,
  DeviceInventoryType,
  PaginatedResponse
} from "@/types";
import { HARDWARE_MAP, getHardware, getSupplier } from "@/utils/mapping";
import { stringify } from "qs";
import { create } from "zustand";
import { STATUS_MAP } from "./device";

type UseExplorerStoreProps = {
  fetchDeviceCities: () => Promise<LocationCityGeoJSON[]>;
  fetchDevicesForCity: (city: LocationCity) => Promise<LocationDevice[]>;
  fetchDevicesForCountry: (country: string) => Promise<LocationDevice[]>;
  fetchLivePricingFilterGroups: () => Promise<FilterGroup[]>;
  fetchLiveHardwarePricing: (filters: Filters) => Promise<LiveHardwarePricing[]>;
  fetchBrands: () => Promise<Brand[]>;
  fetchInferenceStats: () => Promise<InferenceStats>;
  fetchInferences: (option: {
    type?: string;
    status?: string;
  }) => Promise<InferenceDetailResponse[]>;
  fetchInferenceBySearchQuery: (option: { type?: string; searchQuery?: string }) => Promise<
    {
      id: string;
      name: string;
      url: string;
    }[]
  >;
  fetchInferenceDetail: (options: { id: string; type?: string }) => Promise<InferenceDetail>;
  fetchHardwaresForCountry: (country: string) => Promise<CountryHardwaresType>;
  fetchDevicesInventory: (options: {
    page?: number;
    limit?: number;
    filters: Record<string, string[]>;
  }) => Promise<{
    resultCount: number;
    results: DeviceInventoryType[];
    additionalData: Record<string, unknown>;
  }>;
};

export const useExplorerStore = create<UseExplorerStoreProps>((_set, get) => ({
  fetchLivePricingFilterGroups: async () => {
    const { fetchBrands } = get();

    const brands = await fetchBrands();

    return [
      {
        value: "brand",
        label: "Brands",
        items: brands
          .filter(({ value }) => Object.keys(HARDWARE_MAP).includes(value.toLowerCase()))
          .map(({ value, label, icon }) => {
            const color = HARDWARE_MAP[value.toLowerCase()].hardwareManufacturerColor;
            const Icon = icon;
            return {
              value,
              label,
              icon: ({ className }) => <Icon className={`${className} ${color}`} />
            };
          })
      },
      {
        value: "technology",
        label: "Technology",
        items: [
          {
            value: "gpu",
            label: "GPU",
            icon: GpuIcon
          },
          {
            value: "cpu",
            label: "CPU",
            icon: CpuIcon
          }
        ]
      }
    ] as FilterGroup[];
  },
  fetchLiveHardwarePricing: async (filters: Filters) => {
    const response = await executeAPIRequest<{
      data: LiveHardwarePricingResponse[];
      status: string;
    }>({
      method: "get",
      url: `/io-explorer/network/market-snapshot?${stringify({
        connectivity_tier:
          filters["connectivityTier"]?.length || 0 > 0
            ? (filters["connectivityTier"]?.[0] as string).slice(4)
            : undefined,
        hardware_type: filters["technology"]?.[0],
        brand_name: filters["brand"]?.[0],
        supplier_id: filters["supplierId"]?.[0],
        security_soc2:
          (filters["securityCompliance"]?.length || 0) > 0
            ? (filters["securityCompliance"] || []).indexOf("soc2HipAa") > -1
              ? "true"
              : "false"
            : undefined
      })}`
    });

    const { data } = response;

    return (Array.isArray(data) ? data : [data])
      .map((clusterHardwarePricing) => {
        return normaliseClusterHardwarePricing(clusterHardwarePricing);
      })
      .sort((a, b) => {
        return b.total - a.total;
      });
  },
  fetchBrands: async () => {
    const response = await executeAPIRequest<{
      data: BrandResponse[];
      status: string;
    }>({
      method: "get",
      url: `/io-explorer/network/brands`
    });

    return response.data.map(({ name }) => {
      const hardware = name ? getHardware(name) : undefined;

      return {
        value: name,
        label: name,
        ...(hardware
          ? {
              icon: hardware.hardwareManufacturerIcon
            }
          : {})
      } as Brand;
    });
  },
  fetchInferenceStats: async () => {
    const response = await executeAPIRequest<{
      data: InferenceStats;
      status: string;
    }>({
      method: "get",
      url: `/io-explorer/network/info/inferneces`
    });

    return response.data;
  },
  fetchInferences: async ({ type, status }) => {
    const response = await executeAPIRequest<{
      data: InferenceDetailResponse[];
      status: string;
    }>({
      method: "get",
      url: `/io-explorer/inferences/status?${stringify({
        type,
        ...(status === "all" ? {} : { status })
      })}`
    });

    return response.data.map((item) => {
      return {
        ...item,
        href: `/explorer/inferences/${item.inference_id}`
      };
    });
  },
  fetchInferenceBySearchQuery: async ({ type, searchQuery }) => {
    try {
      const response = await executeAPIRequest<{
        data: InferenceDetailResponse[];
        status: string;
      }>({
        method: "get",
        url: `/io-explorer/inferences/search?${stringify({
          type,
          inference_identifier: searchQuery
        })}`
      });
      return response.data.map(({ inference_id }) => {
        return {
          id: inference_id,
          name: inference_id,
          url: `/${type}/inferences/${inference_id}`
        };
      });
    } catch (e) {
      console.log(e);
      return [];
    }
  },
  fetchInferenceDetail: async ({ id, type }) => {
    const response = await executeAPIRequest<{
      data: InferenceDetailResponse;
      status: string;
    }>({
      method: "get",
      url: `/io-explorer/inferences/{inference_id/details?inference_identifier=${id}&type=${type}`
    });

    return normaliseInferenceDetail(response.data);
  },
  fetchDeviceCities: async () => {
    const response = await executeAPIRequest<{
      status: string;
      data: LocationCityResponse[];
    }>({
      method: "get",
      url: `/io-explorer/devices`
    });

    return response.data
      .map((locationCity) => {
        return normalisLocationCity(locationCity);
      })
      .flat();
  },
  fetchDevicesForCity: async (city: LocationCity) => {
    const response = await executeAPIRequest<{
      status: string;
      data: LocationDeviceResponse[];
    }>({
      method: "get",
      url: `/io-explorer/devices?${stringify({
        country_code: city.country,
        city: city.name
      })}`
    });

    return response.data.map((device) => {
      return normalisLocationDevice(device);
    });
  },
  fetchDevicesForCountry: async (country: string) => {
    const response = await executeAPIRequest<{
      status: string;
      data: LocationDeviceResponse[];
    }>({
      method: "get",
      url: `/io-explorer/devices?${stringify({
        country_code: country
      })}`
    });

    return response.data.map((device) => {
      return normalisLocationDevice(device);
    });
  },
  fetchHardwaresForCountry: async (country: string) => {
    const response = await executeAPIRequest<{
      status: string;
      data: CountryHardwaresResponseType;
    }>({
      method: "get",
      url: `/io-explorer/devices/hardware?${stringify({
        country_code: country
      })}`
    });

    return normaliseCountryHardwares(response.data);
  },
  fetchDevicesInventory: async (options) => {
    const { page = 1, limit = 20, filters = {} } = options;

    const response = await executeAPIRequest<{
      status: string;
      data: PaginatedResponse<DeviceInventoryResponseType[]>;
      hardwares: string[];
      hardware_quantities: number[];
      pagination: {
        total_items: number;
      };
    }>({
      method: "get",
      url: `/io-explorer/network/inventory-data?${stringify({
        page,
        limit,
        hardware_names: filters["hardwareNames"]?.join(","),
        regions: filters["regions"]?.join(","),
        connectivity_tiers: filters["connectivityTiers"]?.join(","),
        device_types: filters["deviceTypes"]?.join(","),
        hardware_quantity: filters["hardwareQuantity"]?.join(",")
      })}`
    });

    const { data, hardwares, hardware_quantities, pagination } = response;

    const results = data.map((hardware) => {
      return normaliseHardwareData(hardware);
    });

    return {
      results,
      resultCount: pagination.total_items || 0,
      additionalData: {
        hardwares,
        hardwareQuantities: hardware_quantities
      }
    };
  }
}));

const normalisLocationCity = (result: LocationCityResponse) => {
  const locationParts = result.location.split(", ");
  const coordinates = result.geo_coordinates
    .split(",")
    // we get lat,long from BE but geojson format has long,lat
    .reverse()
    .map((coordinate) => parseFloat(coordinate));

  return Array(result.hardware_quantity).fill({
    type: "Feature",
    properties: {
      cluster: false,
      country: locationParts[0],
      name: locationParts.slice(1).join(", ")
    },
    geometry: {
      type: "Point",
      coordinates
    },
    info: {
      hardwareName: result.hardware_name,
      hardwareQuantity: 1
    }
  }) as LocationCityGeoJSON[];
};

const normalisLocationDevice = (result: LocationDeviceResponse) => {
  const statusType = result.status.toLowerCase();
  const status = STATUS_MAP[`${statusType}` as keyof typeof STATUS_MAP];
  const hardware = getHardware(result.brand_name || "");

  const locationParts = result.city.split(", ");
  const coordinateParts = result.geo_coordinates.split(",");

  return {
    country: locationParts[0],
    city: locationParts.slice(1).join(", "),
    id: result.device_id,
    lat: parseFloat(coordinateParts[0]),
    lon: parseFloat(coordinateParts[1]),
    ...status,
    ...hardware,
    hardwareName: result.hardware_name,
    hardwareQuantity: result.hardware_quantity,
    statusDuration: result.status_duration
  } as LocationDevice;
};

const normaliseClusterHardwarePricing = (result: LiveHardwarePricingResponse) => {
  const hardware = getHardware(result.brand_name);

  return {
    id: `${result.id}`,
    hardwareName: result.hardware_name,
    price: result.price,
    total: result.total,
    ...hardware,
    hardwareManufacturer: result.brand_name,
    hired: result.hired,
    idle: result.idle
  } as LiveHardwarePricing;
};

const normaliseInferenceDetail = (result: InferenceDetailResponse) => {
  const supplierName = result.supplier.name;
  const supplier = getSupplier(supplierName);

  return {
    appInferencePrice: result.app_inference_price,
    appInferenceTxidUrl: result.app_inference_txid_url,
    appName: result.app_name,
    clusterId: result.cluster_id,
    computeDuration: result.compute_duration,
    createdAt: result.created_at,
    inferenceId: result.inference_id,
    inferenceVerificationUrl: result.inference_verification_url,
    ioNetworkFee: result.io_network_fee,
    ioNetworkFeeTxidUrl: result.io_network_fee_txid_url,
    modelOwnerRoyaltyFee: result.model_owner_royalty_fee,
    modelOwnerRoyaltyFeeTxidUrl: result.model_owner_royalty_fee_txid_url,
    requestedBy: result.requested_by,
    computeCost: result.compute_cost,
    status: result.status,
    computeCostTxidUrl: result.compute_cost_txid_url,
    ...(supplier && supplierName
      ? {
          supplierName,
          supplierIcon: supplier.icon
        }
      : {})
  } as InferenceDetail;
};

const normaliseCountryHardwares = (result: CountryHardwaresResponseType) => {
  return {
    hardware: result.hardware,
    totalGpu: result.total_gpu,
    totalCpu: result.total_cpu,
    totalGpuHired: result.total_gpu_hired,
    totalGpuIdle: result.total_gpu_idle,
    totalCpuHired: result.total_cpu_hired,
    totalCpuIdle: result.total_cpu_idle
  } as CountryHardwaresType;
};

const normaliseHardwareData = (hardware: DeviceInventoryResponseType) => {
  return {
    hardwareName: hardware.hardware_name,
    numOfGpus: hardware.num_of_gpus,
    region: hardware.region,
    connectivityTier: CONNECTIVITY_TIER_OPTIONS.find(
      (option) => option.value === hardware.connectivity_tier
    ),
    deviceType: hardware.device_type
  } as DeviceInventoryType;
};

export const CONNECTIVITY_TIER_OPTIONS = [
  {
    value: 1,
    label: "Low Speed",
    icon: () => "🐌"
  },
  {
    value: 2,
    label: "Medium Speed",
    icon: () => "⚡️"
  },
  {
    value: 3,
    label: "High Speed",
    icon: () => "⚡️⚡️"
  },
  {
    value: 4,
    label: "Ultra High Speed",
    icon: () => "⚡️⚡️⚡️"
  }
] as {
  value: number;
  label: string;
  icon?: () => string;
}[];
