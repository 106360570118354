import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded label-small ring-offset-background transition-colors duration-300 ease-out focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:bg-gray-dark-600 disabled:border-gray-dark-900 disabled:border disabled:text-gray-dark-1200 tracking-[-.0.6%] [&_svg]:transition-colors [&_svg]:duration-300 [&_svg]:hover:text-gray-dark-1200",
  {
    variants: {
      variant: {
        default: "bg-gray-light-1000 text-black hover:bg-gray-light-1600",
        outline:
          "border border-input bg-gray-dark-600 border-gray-dark-900 text-white hover:bg-gray-dark-1100 hover:text-white hover:border-gray-dark-1200 [&_svg]:hover:text-gray-dark-1000",
        ghost:
          "hover:bg-gray-dark-1100 hover:text-white border-0 [&_svg]:hover:text-gray-dark-1000",
        link: "text-primary underline-offset-4 hover:underline",
        error:
          "bg-red-dark-400 hover:bg-opacity-[32%] text-red-light-800 hover:text-red-dark-300 bg-opacity-25"
      },
      size: {
        default: "h-10 px-4 py-2",
        xs: "h-8 rounded-[6px] px-3",
        sm: "h-9 rounded-[6px] px-3 min-h-[36px]",
        icon: "h-9 w-9"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        type="button"
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
