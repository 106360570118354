import { ZustandDefinition } from "@/types";
import { create } from "zustand";
import { useClusterStore } from "./cluster";
import { toFriendlyError } from "@/utils/api";
import { useDeviceStore } from "./device";
import { DEFAULT_PAGINATION_LIMIT } from "@/constants";
import { useUserStore } from "./user";
import { useBlockRewardsStore } from "./blockRewards";
import { useExplorerStore } from "./explorer";
import { useStakingStore } from "./staking";
import { useBareMetalStore } from "@/store/simpleCluster";
import { useKubernetesStore } from "@/store/simpleCluster";

export type UsePaginatedStoreProps = {
  isLoading: boolean;
  page: number;
  pageCount: number;
  maxPageCount?: number;
  limit: number;
  hasResults?: boolean;
  resultCount?: number;
  results?: unknown[];
  response: Record<string, unknown>;
  fetchResultsError?: Error | undefined;
  resultsRequestOptions: { [k: string]: unknown };
  resultsRequestHandler?: PaginatedResultsRequestHandler;
  statuses?: string[];
  setPage: (page: number) => void;
  setLimit: (limit: number) => void;
  setResultsRequestOptions: (requestOptions: { [k: string]: unknown }) => void;
  setResultsRequestHandler: <T>(callback: PaginatedResultsRequestHandler<T>) => void;
  fetchResults: () => Promise<void>;
  reset: () => void;
  additionalData?: Record<string, unknown>;
};

type PaginatedResultsRequestHandler<T = unknown> = (options: {
  page: number;
  limit: number;
}) => Promise<{
  results: T[];
  resultCount?: number;
  statuses?: string[];
  additionalData?: Record<string, unknown>;
}>;

const paginatedStoreDefinition: ZustandDefinition<UsePaginatedStoreProps> = (set, get) => ({
  isLoading: false,
  page: 1,
  pageCount: 1,
  limit: DEFAULT_PAGINATION_LIMIT,
  resultsRequestOptions: {},
  response: {},
  setPage: (page: number) => {
    set({ page });
  },
  setLimit: (limit: number) => {
    set({ limit });
  },
  setResultsRequestHandler: (resultsRequestHandler) => {
    set({ resultsRequestHandler });
  },
  setResultsRequestOptions: (resultsRequestOptions) => {
    const { reset } = get();

    reset();
    set({ resultsRequestOptions });
  },
  fetchResults: async () => {
    const {
      page,
      results: existingResults,
      limit,
      isLoading,
      resultsRequestOptions,
      resultsRequestHandler
    } = get();
    if (isLoading || !resultsRequestHandler) {
      return;
    }

    set({ isLoading: true });

    try {
      const response = await resultsRequestHandler({
        ...resultsRequestOptions,
        page,
        limit
      });

      const { results, resultCount, statuses, additionalData, ...rest } = response;
      const isLoadMore = false;
      const newResults = ((page > 0 && isLoadMore ? existingResults : []) || []).concat(results);
      const hasResults = newResults.length > 0;
      const pageCount =
        typeof resultCount !== "number" ? 0 : Math.max(1, Math.ceil(resultCount / limit));

      if (page > 1 && results.length === 0) {
        set({ maxPageCount: page - 1, page: page - 1 });
        return;
      }

      set({
        resultCount,
        response: rest || {},
        results: newResults,
        isLoading: false,
        hasResults,
        pageCount,
        statuses,
        additionalData,
        fetchResultsError: undefined,
        maxPageCount: undefined
      });
    } catch (e) {
      const fetchResultsError = toFriendlyError(e);

      if (e instanceof Error) {
        set({ fetchResultsError });
      }
    } finally {
      set({ isLoading: false });
    }
  },
  reset: async () => {
    set({
      isLoading: false,
      results: undefined,
      resultCount: undefined,
      hasResults: undefined,
      page: 1,
      resultsRequestOptions: {},
      fetchResultsError: undefined
    });
  }
});

export const usePaginatedBareMetalStore = create<UsePaginatedStoreProps>(paginatedStoreDefinition);
usePaginatedBareMetalStore.getState().setResultsRequestHandler(async (options) => {
  return await useBareMetalStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchSimpleClusterRequest(options as any);
});

export const usePaginatedKubernetesStore = create<UsePaginatedStoreProps>(paginatedStoreDefinition);
usePaginatedKubernetesStore.getState().setResultsRequestHandler(async (options) => {
  return await useKubernetesStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchSimpleClusterRequest(options as any);
});

export const usePaginatedClustersStore = create<UsePaginatedStoreProps>(paginatedStoreDefinition);
usePaginatedClustersStore.getState().setResultsRequestHandler(async (options) => {
  return await useClusterStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchClustersRequest(options as any);
});

export const usePaginatedRunningClustersStore =
  create<UsePaginatedStoreProps>(paginatedStoreDefinition);

usePaginatedRunningClustersStore.getState().setResultsRequestHandler(async (options) => {
  return await useClusterStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchClustersRequest(options as any);
});

export const usePaginatedDevicesStore = create<UsePaginatedStoreProps>(paginatedStoreDefinition);

usePaginatedDevicesStore.getState().setResultsRequestHandler(async (options) => {
  return await useDeviceStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchDevicesRequest(options as any);
});

export const usePaginatedMiddlewareUserStakedDeviceStore =
  create<UsePaginatedStoreProps>(paginatedStoreDefinition);

usePaginatedMiddlewareUserStakedDeviceStore.getState().setResultsRequestHandler(async (options) => {
  return await useStakingStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchUserStakedDevicesRequest(options as any);
});

export const usePaginatedMiddlewareStakeEligibleDeviceStore =
  create<UsePaginatedStoreProps>(paginatedStoreDefinition);

usePaginatedMiddlewareStakeEligibleDeviceStore
  .getState()
  .setResultsRequestHandler(async (options) => {
    return await useStakingStore
      .getState()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .fetchMiddlewareStakeEligibleDevicesRequest(options as any);
  });

export const usePaginatedUserStakedDeviceStore =
  create<UsePaginatedStoreProps>(paginatedStoreDefinition);

usePaginatedUserStakedDeviceStore.getState().setResultsRequestHandler(async (options) => {
  return await useStakingStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchUserStakedDevicesRequest(options as any);
});

usePaginatedUserStakedDeviceStore.getState().limit = 200;

export const usePaginatedDeviceHistoricalJobsStore =
  create<UsePaginatedStoreProps>(paginatedStoreDefinition);

usePaginatedDeviceHistoricalJobsStore.getState().setResultsRequestHandler(async (options) => {
  return await useDeviceStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchDeviceHistoricalJobsRequest(options as any);
});

export const usePaginatedDeviceJobsStore = create<UsePaginatedStoreProps>(paginatedStoreDefinition);

usePaginatedDeviceJobsStore.getState().setResultsRequestHandler(async (options) => {
  return await useDeviceStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchDeviceJobsRequest(options as any);
});

export const usePaginatedUserNotificationStore =
  create<UsePaginatedStoreProps>(paginatedStoreDefinition);

usePaginatedUserNotificationStore.getState().setResultsRequestHandler(async (options) => {
  return await useUserStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchNotificationsRequest(options as any);
});

export const usePaginatedTransactionsStore =
  create<UsePaginatedStoreProps>(paginatedStoreDefinition);

usePaginatedTransactionsStore.getState().setResultsRequestHandler(async (options) => {
  return await useUserStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchTransactionsRequest(options as any);
});

export const usePaginatedDeviceProofOfTimeStore =
  create<UsePaginatedStoreProps>(paginatedStoreDefinition);

usePaginatedDeviceProofOfTimeStore.getState().setResultsRequestHandler(async (options) => {
  return await useDeviceStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchDeviceProofOfTime(options as any);
});

export const usePaginatedDeviceProofOfWorkStore =
  create<UsePaginatedStoreProps>(paginatedStoreDefinition);

usePaginatedDeviceProofOfWorkStore.getState().setResultsRequestHandler(async (options) => {
  return await useDeviceStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchDeviceProofOfWork(options as any);
});

export const usePaginatedDeviceBlockRewardsStore =
  create<UsePaginatedStoreProps>(paginatedStoreDefinition);

usePaginatedDeviceBlockRewardsStore.getState().setResultsRequestHandler(async (options) => {
  return await useDeviceStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchDeviceBlockRewards(options as any);
});

export const usePaginatedBlockRewardsStore =
  create<UsePaginatedStoreProps>(paginatedStoreDefinition);

usePaginatedBlockRewardsStore.getState().setResultsRequestHandler(async (options) => {
  return await useBlockRewardsStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchResults(options as any);
});

export const usePaginatedBlockRecordsStore =
  create<UsePaginatedStoreProps>(paginatedStoreDefinition);

usePaginatedBlockRecordsStore.getState().setResultsRequestHandler(async (options) => {
  return await useBlockRewardsStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchBlockRecordResults(options as any);
});

export const usePaginatedDeviceInventoryStore =
  create<UsePaginatedStoreProps>(paginatedStoreDefinition);

usePaginatedDeviceInventoryStore.getState().setResultsRequestHandler(async (options) => {
  return await useExplorerStore
    .getState()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .fetchDevicesInventory(options as any);
});
